import React from 'react';
import { block2Card } from '..';

const WhyCard = () => {
  return (
    <div className="block2-cards-container container-fluid">
      <div className="row no-gutters">{block2Card}</div>
    </div>
  );
};

export default WhyCard;
