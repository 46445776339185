import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import whyFreeHero from '../../../images/new-landing/why-free-hero.png';
import appUrls from '../../../constants/appUrls';

import { ButtonNew, Text } from '../../../components';

import './index.scss';

const Intro = () => {
  return (
    <Row className="section-why-free" noGutters>
      <Col
        xs={{ offset: 1, span: 10 }}
        md={{ offset: 1, span: 6 }}
        className="order-1 order-md-0"
      >
        <Text as="h2" color="light" bold>
          <Localize id="homePage.SectionWhyFree.Title" />
        </Text>
        <Text color="light" as="p">
          <Localize id="homePage.SectionWhyFree.Description" />
        </Text>
        <ButtonNew onClick={appUrls.REGISTER}>
          <Localize id="homePage.SectionWhyFree.CTA" />
        </ButtonNew>
      </Col>
      <Col md={4} className="section-why-free-hero order-0 order-md-1">
        <img loading="lazy" src={whyFreeHero} alt="" />
      </Col>
    </Row>
  );
};

export default Intro;
