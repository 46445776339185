import PropTypes from 'prop-types';
import React, {
  memo,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import cn from 'classnames';
import ReactDOM from 'react-dom';

import { useOutsideClick } from '../../../utils';

import './index.scss';

const Modal = ({
  className,
  children,
  getRootDOMNode,
  show,
  onClose,
  fullscreen,
  onOutsideClick,
}) => {
  const modalRef = useRef();

  const [rootNode, setRootNode] = useState(null);

  const outsideClickCallback = useCallback(
    (...args) => show && onOutsideClick(...args),
    [show, onOutsideClick]
  );

  useOutsideClick(outsideClickCallback, modalRef);

  const modalClassNames = useMemo(
    () =>
      cn('modal-container', className, {
        'modal-container--fullscreen': fullscreen,
      }),
    [className, fullscreen]
  );

  useEffect(() => {
    setRootNode(getRootDOMNode());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (
    <>
      <div className="modal-container-background" />
      <div className={modalClassNames} ref={modalRef}>
        {onClose && (
          <div
            className="modal-container__close-button"
            onKeyPress={onClose}
            onClick={onClose}
            type="button"
          >
            &times;
          </div>
        )}
        {children}
      </div>
    </>
  );

  if (!rootNode || !show) {
    return null;
  }

  return ReactDOM.createPortal(content, rootNode);
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  getRootDOMNode: PropTypes.func,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  fullscreen: PropTypes.bool,
  onOutsideClick: PropTypes.func,
};

Modal.defaultProps = {
  className: '',
  getRootDOMNode: () => document.getElementById('___gatsby'),
  onClose: null,
  show: false,
  fullscreen: false,
  onOutsideClick: () => {},
};

export default memo(Modal);
