const SMART = {
  ONE_MONTH: 'kokolingo-smart-one-month',
  SIX_MONTHS: 'kokolingo-smart-six-months',
};

const BASIC = {
  ONE_MONTH: 'kokolingo-basic-one-month',
  SIX_MONTHS: 'kokolingo-basic-six-months',
};

const MINI = {
  ONE_MONTH: 'kokolingo-mini-one-month',
  SIX_MONTHS: 'kokolingo-mini-six-months',
};

export default { SMART, BASIC, MINI };
