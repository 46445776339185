import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Text, Icon } from '../../../components';

import './index.scss';

const FAQ = ({ answers, openIconName, closeIconName }) => {
  const [activeAnswers, setActive] = useState({});

  return (
    <div className="faq-new">
      {answers.map(({ question, answer }, index) => (
        <div
          key={index}
          className={`faq-new-item ${!!activeAnswers[index] && 'active'}`}
          onClick={() =>
            setActive({
              ...activeAnswers,
              [index]: !activeAnswers[index],
            })
          }
        >
          <Icon
            name={activeAnswers[index] ? closeIconName : openIconName}
            circle={false}
            variant="ghost"
          />
          <Text as="h4">{question}</Text>
          <Text>{answer}</Text>
        </div>
      ))}
    </div>
  );
};

FAQ.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.node,
      answer: PropTypes.node,
    })
  ).isRequired,
  openIconName: PropTypes.string,
  closeIconName: PropTypes.string,
};

FAQ.defaultProps = {
  openIconName: 'plus',
  closeIconName: 'minus',
};

export default FAQ;
