import React from 'react';

export default () => (
  <svg
    width="9"
    height="16"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.573314 14.8282C0.00681271 14.2617 0.00681271 13.3432 0.573314 12.7767L5.35004 7.99997L0.573314 3.22325C0.00681216 2.65675 0.00681211 1.73826 0.573314 1.17176C1.13981 0.605262 2.0583 0.605262 2.6248 1.17176L8.42727 6.97423C8.99377 7.54073 8.99377 8.45921 8.42727 9.02571L2.6248 14.8282C2.0583 15.3947 1.13982 15.3947 0.573314 14.8282Z"
      fill="currentColor"
    />
  </svg>
);
