import React from 'react';
import { Localize } from '@kokolingo/localisation';
import Contacts from '../Contacts';

import './index.css';
import { FooterHeader } from '../../index';

const Footer = () => {
  return (
    <div className="footer">
      <div className="container-fluid footer-container">
        <FooterHeader />
      </div>
      <div className="container-fluid footer-contact-container">
        <div className="row text-center no-gutters">
          <Contacts />
        </div>
      </div>

      <div className="container-fluid footer-container">
        <div className="row">
          <div className="col-12 text-center copyright">
            <Localize id="footer.Copyright" />
          </div>
          <div className="col-12 text-center copyright">
            <Localize id="footer.AddressPhone" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
