import React from 'react';
import PropTypes from 'prop-types';
import { Localize } from '@kokolingo/localisation';

import routes from '../../routes';

import next from '../../images/educational-corner/left.png';
import prev from '../../images/educational-corner/right.png';

import './index.css';

const BlogControls = ({ nextPage, prevPage }) => {
  return (
    <div className="blog__controls">
      <div
        className="blog__controls--left"
        onClick={() => {
          window.open(prevPage, '_self');
        }}
      >
        <img
          alt="Prijasnji clanak"
          className="blog__controls__button"
          src={next}
        />
        <Localize id="educationalCorner.PreviousPage" />
      </div>
      <div
        className="blog__controls--right"
        onClick={() => {
          window.open(nextPage, '_self');
        }}
      >
        <Localize id="educationalCorner.NextPage" />
        <img
          alt="Sljedeci clanak"
          className="blog__controls__button"
          src={prev}
        />
      </div>
    </div>
  );
};

BlogControls.propTypes = {
  nextPage: PropTypes.string,
  prevPage: PropTypes.string,
};

BlogControls.defaultProps = {
  nextPage: routes.SPEECH_DISORDER,
  prevPage: routes.HOW_TO_FIND_TIME_FOR_KIDS,
};

export default BlogControls;
