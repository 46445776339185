import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.css';

const Title = ({ variant, children, className }) => {
  const titleClassName = cn('title', `title--${variant}`, className);

  return <div className={titleClassName}>{children}</div>;
};

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['dark', 'light']),
};

Title.defaultProps = {
  className: '',
  variant: 'dark',
};

export default Title;
