import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const AshaCard = ({ children, size }) => {
  const className = cn('asha-card', `asha-card__${size}`);
  return <div className={className}>{children}</div>;
};

AshaCard.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['large', 'small']),
};

AshaCard.defaultProps = {
  size: 'small',
};

const AshaBadge = ({ children }) => (
  <>
    <div className="card-badge">{children}</div>
    <div className="card-badge-clearfix" />
  </>
);

AshaBadge.propTypes = {
  children: PropTypes.node.isRequired,
};

AshaCard.AshaBadge = AshaBadge;

export default AshaCard;
