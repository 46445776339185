import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import footerKoko from '../../../images/new-landing/footer-koko.svg';

import { Text } from '../../../components';

import RegisterForm from '../RegisterForm';

import './index.scss';

const Footer = () => {
  return (
    <Row className="section-footer" noGutters>
      <Col
        className="section-footer-content order-1 order-md-0"
        xs={12}
        md={{ offset: 2, span: 5 }}
      >
        <Text as="h3" light bold color="light">
          <Localize id="homePage.SectionFooter.Title" />
        </Text>
        <Text color="light">
          <Localize id="homePage.SectionFooter.Description" />
        </Text>
        <div className="section-footer-quick-start">
          <RegisterForm />
        </div>
      </Col>
      <Col
        className="section-footer-logo order-0 order-md-1"
        sm={12}
        md={{ offset: 1, span: 3 }}
      >
        <img loading="lazy" src={footerKoko} alt="" />
      </Col>
    </Row>
  );
};

export default Footer;
