import React from 'react';
import PropTypes from 'prop-types';
import { Localize } from '@kokolingo/localisation';

import Text from './Text';

import checkMark from '../images/kvačica.png';
import crossMark from '../images/izlaz.png';

const PricingItems = ({ packages, useText }) => {
  return (
    <table>
      {packages.map((element, index) => (
        <tbody className="pricing-table pricing-text" key={index}>
          <tr>
            <td>
              <Localize.Img
                src={element.available ? checkMark : crossMark}
                altID={element.descriptionId}
                titleID={element.descriptionId}
              />
            </td>
            <td>
              {useText ? (
                <Text>
                  <Localize id={element.descriptionId} />
                </Text>
              ) : (
                <Localize id={element.descriptionId} />
              )}
            </td>
          </tr>
        </tbody>
      ))}
    </table>
  );
};

PricingItems.propTypes = {
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      available: PropTypes.bool.isRequired,
      descriptionId: PropTypes.string.isRequired,
    })
  ).isRequired,
  useText: PropTypes.bool,
};

PricingItems.defaultProps = {
  useText: false,
};

export default PricingItems;
