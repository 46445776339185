import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const CustomTooltip = ({
  show,
  tooltipMessage,
  placement,
  trigger,
  children,
}) => {
  const renderTooltip = ({ message }) => {
    return <Tooltip id="tooltip">{message}</Tooltip>;
  };

  return (
    <OverlayTrigger
      show={show}
      placement={placement}
      overlay={renderTooltip({
        message: tooltipMessage,
      })}
      trigger={trigger}
    >
      {children}
    </OverlayTrigger>
  );
};

CustomTooltip.propTypes = {
  show: PropTypes.bool,
  tooltipMessage: PropTypes.string.isRequired,
  placement: PropTypes.string,
  children: PropTypes.element.isRequired,
  trigger: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

CustomTooltip.defaultProps = {
  show: undefined,
  placement: undefined,
  trigger: undefined,
};

export default CustomTooltip;
