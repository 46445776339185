import React from 'react';

import kokoTheParrotShop1 from '../hr/images/trgovina_za_papigu_koko_1.png';
import kokoTheParrotShop2 from '../hr/images/trgovina_za_papigu_koko_2.png';
import kokoTheParrotShop3 from '../hr/images/trgovina_za_papigu_koko_3.png';
import kokoTheParrotShop4 from '../hr/images/trgovina_za_papigu_koko_4.png';

export default [
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={kokoTheParrotShop1}
      title="Dressed parrot Koko"
      alt="Dressed parrot Koko"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={kokoTheParrotShop2}
      title="Koko parrot hat shop"
      alt="Koko parrot hat shop"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={kokoTheParrotShop3}
      title="Koko parrot accessories"
      alt="Koko parrot accessories"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={kokoTheParrotShop4}
      title="Koko parrot accessories"
      alt="Koko parrot accessories"
    />
  </div>,
];
