import React from 'react';
import PropTypes from 'prop-types';

const BlogInfographics = ({ infographicSrc }) => {
  return (
    <img
      loading="lazy"
      alt="Infografika trenutnog bloga"
      className="blog__image infographics"
      src={infographicSrc}
      onClick={() => {
        window.open(infographicSrc);
      }}
    />
  );
};

BlogInfographics.propTypes = {
  infographicSrc: PropTypes.string.isRequired,
};

export default BlogInfographics;
