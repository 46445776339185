import React from 'react';
import { Localize } from '@kokolingo/localisation';

export default [
  {
    question: <Localize id="FAQ.Question1" />,
    answer: <Localize id="FAQ.Answer1" />,
  },
  {
    question: <Localize id="FAQ.Question2" />,
    answer: <Localize id="FAQ.Answer2" />,
  },
  {
    question: <Localize id="FAQ.Question3" />,
    answer: <Localize id="FAQ.Answer3" />,
  },
  {
    question: <Localize id="FAQ.Question4" />,
    answer: <Localize id="FAQ.Answer4" />,
  },
  {
    question: <Localize id="FAQ.Question5" />,
    answer: <Localize id="FAQ.Answer5" />,
  },
  {
    question: <Localize id="FAQ.Question6" />,
    answer: <Localize id="FAQ.Answer6" />,
  },
  {
    question: <Localize id="FAQ.Question7" />,
    answer: <Localize id="FAQ.Answer7" />,
  },
  {
    question: <Localize id="FAQ.Question8" />,
    answer: <Localize id="FAQ.Answer8" />,
  },
  {
    question: <Localize id="FAQ.Question9" />,
    answer: <Localize id="FAQ.Answer9" />,
  },
  {
    question: <Localize id="FAQ.Question10" />,
    answer: <Localize id="FAQ.Answer10" />,
  },
  {
    question: <Localize id="FAQ.Question11" />,
    answer: <Localize id="FAQ.Answer11" />,
  },
  {
    question: <Localize id="FAQ.Question12" />,
    answer: <Localize id="FAQ.Answer12" />,
  },
  {
    question: <Localize id="FAQ.Question13" />,
    answer: <Localize id="FAQ.Answer13" />,
  },
  {
    question: <Localize id="FAQ.Question14" />,
    answer: <Localize id="FAQ.Answer14" />,
  },
  {
    question: <Localize id="FAQ.Question15" />,
    answer: <Localize id="FAQ.Answer15" />,
  },
  {
    question: <Localize id="FAQ.Question16" />,
    answer: <Localize id="FAQ.Answer16" />,
  },
];
