import React from 'react';
import PropTypes from 'prop-types';

const Block2Card = ({ img, title, description }) => {
  return (
    <div className="block2-card">
      <div className="block2-card-title">
        {img}
        <h1 className="block2-card-title-text">{title}</h1>
      </div>
      <div className="block2-card-desc">{description}</div>
    </div>
  );
};

Block2Card.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  img: PropTypes.node.isRequired,
};

export default Block2Card;
