import React from 'react';
import { Localize } from '@kokolingo/localisation';

// eslint-disable-next-line import/no-dynamic-require
const logo = require(`../../images/${process.env.GATSBY_LOCALE}-logo.png`);

export default () => (
  <Localize.Img
    titleID="footer.company"
    altID="footer.company"
    src={logo}
    className="eglas-logo"
  />
);
