const PREMIUM = [
  {
    available: true,
    descriptionId: 'pricing.ArticulationGymnastics',
  },
  {
    available: true,
    descriptionId: 'pricing.TreasureHunt',
  },
  {
    available: true,
    descriptionId: 'pricing.Painting',
  },
  {
    available: true,
    descriptionId: 'pricing.Climb',
  },
  {
    available: true,
    descriptionId: 'pricing.Discover',
  },
  {
    available: true,
    descriptionId: 'pricing.FlashCards',
  },
  {
    available: true,
    descriptionId: 'pricing.FindWords',
  },
  {
    available: true,
    descriptionId: 'pricing.Memory',
  },
  {
    available: true,
    descriptionId: 'pricing.WheelOfFortune',
  },
  {
    available: true,
    descriptionId: 'pricing.Replenishment',
  },
  {
    available: true,
    descriptionId: 'pricing.ReadTogether',
  },
  {
    available: true,
    descriptionId: 'pricing.ReadAndThink',
  },
];

const SMART = [
  {
    available: true,
    descriptionId: 'pricing.ArticulationGymnastics',
  },
  {
    available: true,
    descriptionId: 'pricing.TreasureHunt',
  },
  {
    available: true,
    descriptionId: 'pricing.Painting',
  },
  {
    available: true,
    descriptionId: 'pricing.Climb',
  },
  {
    available: false,
    descriptionId: 'pricing.Discover',
  },
  {
    available: true,
    descriptionId: 'pricing.FlashCards',
  },
  {
    available: true,
    descriptionId: 'pricing.FindWords',
  },
  {
    available: true,
    descriptionId: 'pricing.Memory',
  },
  {
    available: true,
    descriptionId: 'pricing.WheelOfFortune',
  },
  {
    available: true,
    descriptionId: 'pricing.Replenishment',
  },
  {
    available: true,
    descriptionId: 'pricing.ReadTogether',
  },
  {
    available: false,
    descriptionId: 'pricing.ReadAndThink',
  },
];

const MINI = [
  {
    available: true,
    descriptionId: 'pricing.ArticulationGymnastics',
  },
  {
    available: true,
    descriptionId: 'pricing.TreasureHunt',
  },
  {
    available: false,
    descriptionId: 'pricing.Painting',
  },
  {
    available: true,
    descriptionId: 'pricing.Climb',
  },
  {
    available: false,
    descriptionId: 'pricing.Discover',
  },
  {
    available: true,
    descriptionId: 'pricing.FlashCards',
  },
  {
    available: false,
    descriptionId: 'pricing.FindWords',
  },
  {
    available: false,
    descriptionId: 'pricing.Memory',
  },
  {
    available: true,
    descriptionId: 'pricing.WheelOfFortune',
  },
  {
    available: false,
    descriptionId: 'pricing.Replenishment',
  },
  {
    available: true,
    descriptionId: 'pricing.ReadTogether',
  },
  {
    available: false,
    descriptionId: 'pricing.ReadAndThink',
  },
];

const TELELOGO = [
  {
    available: true,
    descriptionId: 'teletherapy.SectionPricing.monthlySubscription',
  },
  {
    available: true,
    descriptionId: 'teletherapy.SectionPricing.slpReport',
  },
  {
    available: true,
    descriptionId: 'teletherapy.SectionPricing.slpProgram',
  },
];

const JUNIOR = [
  {
    available: true,
    descriptionId: 'teletherapy.SectionPricing.item1',
  },
  {
    available: true,
    descriptionId: 'teletherapy.SectionPricing.Junior.item2',
  },
  {
    available: true,
    descriptionId: 'teletherapy.SectionPricing.item3',
  },
  {
    available: false,
    descriptionId: 'teletherapy.SectionPricing.item4',
  },
  {
    available: false,
    descriptionId: 'teletherapy.SectionPricing.item5',
  },
];

const TELELOGOPLUS = [
  {
    available: true,
    descriptionId: 'teletherapy.SectionPricing.item1',
  },
  {
    available: true,
    descriptionId: 'teletherapy.SectionPricing.Ekspert.item2',
  },
  {
    available: true,
    descriptionId: 'teletherapy.SectionPricing.item3',
  },
  {
    available: true,
    descriptionId: 'teletherapy.SectionPricing.item4',
  },
  {
    available: true,
    descriptionId: 'teletherapy.SectionPricing.item5',
  },
];

export default { PREMIUM, SMART, MINI, TELELOGO, JUNIOR, TELELOGOPLUS };
