import React from 'react';
import PropTypes from 'prop-types';

import { app } from '../../utils';
import './index.css';

const BlogLink = ({ children, src, title }) => {
  return (
    <a
      className="blog__link"
      href={src}
      title={title}
      target="_blank"
      rel="noopener noreferrer"
      hrefLang="hr"
    >
      <b>{children}</b>
    </a>
  );
};

BlogLink.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
  title: PropTypes.string,
};
BlogLink.defaultProps = {
  children: null,
  src: app.linkApp(),
  title: 'Kokolingo aplikacija',
};

export default BlogLink;
