import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Head, Header, Text } from '../../../components';
import { TopBar } from '../..';
import Footer from '../FooterNew';

const Page = ({ children, title, description, headline, wrapper }) => {
  return (
    <>
      <Head
        title={title}
        description={description}
        siteUrl={process.env.GATSBY_SITE_URL}
      />
      <TopBar title={title} />
      {wrapper({ children, headline })}
      <Footer />
    </>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  headline: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  wrapper: PropTypes.func,
};

Page.defaultProps = {
  children: null,
  title: 'Kokolingo',
  wrapper: ({ children, headline }) => (
    <>
      <Header className="mb-5 pl-3">
        <Text as="h1" color="light" bold>
          {headline}
        </Text>
      </Header>
      <Row noGutters className="mb-5">
        <Col xs={{ offset: 1, span: 10 }}>{children}</Col>
      </Row>
    </>
  ),
};

export default Page;
