import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Localize } from '@kokolingo/localisation';
import Title from '../Title';

import play from '../../images/pokreni.png';
import { VideoThumbnail } from '../../images';

import './index.css';

const Video = ({ src }) => {
  const video = useRef(null);

  const [activeClass, setActiveClass] = useState('video-btn pause');

  const playVideo = useCallback(() => {
    video.current.play();
    setActiveClass('video-btn pause');
  }, [setActiveClass, video]);

  const pauseVideo = useCallback(() => {
    setActiveClass('video-btn active');
  }, [setActiveClass]);

  useEffect(() => {
    pauseVideo();
  }, [pauseVideo]);

  return (
    <div className="block">
      <div className="block__container">
        <Title variant="dark">
          <span className="anchor" id="video-prica" />
          <Localize id="homePage.Video" />
        </Title>
        <div className="block__video-container">
          <img
            loading="lazy"
            alt="Gumb za pokretanje Kokolingo videa"
            title="Pokrenite Kokolingo video"
            id="video-btn"
            className={activeClass}
            onClick={playVideo}
            src={play}
          />
          <video
            id="video"
            ref={video}
            onPlay={playVideo}
            onPause={pauseVideo}
            poster={VideoThumbnail}
            controls
          >
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Video;
