import React from 'react';

import { ButtonNew, AshaSlider, AshaVideo } from '../../../components';

import './index.scss';

const AshaFragment = () => {
  return (
    <div>
      <AshaSlider />
      <AshaVideo />
      <div className="asha-button-block">
        <ButtonNew
          className="button-new1"
          onClick={
            (() =>
              window.open(
                `${process.env.GATSBY_APP_URL}/register/speech-therapist`
              ),
            '_blank')
          }
        >
          Start Kokolingo 30 day free trial
        </ButtonNew>
      </div>
    </div>
  );
};

export default AshaFragment;
