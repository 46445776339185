import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { Text } from '../../../components';

import './index.scss';

const CardPost = ({ title, img, subtitle, url }) => {
  return (
    <Link to={url}>
      <div className="card-post">
        <img loading="lazy" src={img} alt="" />
        <div className="card-post-body">
          <Text as="h4">{title}</Text>
          <Text ellipsis as="p">
            {subtitle}
          </Text>
        </div>
      </div>
    </Link>
  );
};

CardPost.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default CardPost;
