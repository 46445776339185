import { useEffect } from 'react';

const useScript = ({
  tagName,
  attributes,
  children = null,
  appendNodeName = 'body',
}) => {
  useEffect(() => {
    const tag = document.createElement(tagName);

    Object.keys(attributes).forEach((key) => {
      tag.setAttribute(key, attributes[key]);
    });

    tag.innerHTML = children;

    document[appendNodeName].appendChild(tag);

    return () => {
      document[appendNodeName].removeChild(tag);
    };
  }, []);
};

export default useScript;
