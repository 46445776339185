import React from 'react';
import PropTypes from 'prop-types';
import { Localize } from '@kokolingo/localisation';

import './index.css';

const BlogCredits = ({ children, author }) => {
  return (
    <div className="blog__credits">
      <p>
        <b>
          <Localize id="educationalCorner.Author" />
        </b>{' '}
        {author}
      </p>
      {children}
    </div>
  );
};

const BlogLiterature = ({ children, className }) => {
  return (
    <div className={className}>
      <p>
        <b>
          <Localize id="educationalCorner.Literature" />
        </b>
        :
      </p>
      {children}
    </div>
  );
};

BlogCredits.propTypes = {
  children: PropTypes.node,
  author: PropTypes.node.isRequired,
};

BlogCredits.defaultProps = {
  children: null,
};

BlogLiterature.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

BlogLiterature.defaultProps = {
  className: '',
};

export { BlogCredits, BlogLiterature };
