import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';

import headerLogo from '../../../images/new-landing/header-logo.svg';

import appUrls from '../../../constants/appUrls';
import routes from '../../../routes';

import { ButtonNew, LanguageDropdown } from '../../../components';

import './index.scss';

const TopBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuContainerClassName = `topbar-menu-container ${
    isOpen ? 'topbar-menu-container__open' : ''
  }`;

  return (
    <Row className="topbar" noGutters>
      <Col className="logo" md={4} xs={12}>
        <Link to="/">
          <img src={headerLogo} alt="Kokolingo logo" />
        </Link>
        <span
          className={`topbar-hamburger ${
            isOpen ? 'topbar-hamburger-open' : 'topbar-hamburger-close'
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span />
          <span />
          <span />
        </span>
      </Col>
      <Col className={menuContainerClassName} md={7}>
        <Row className="flex-md-nowrap" noGutters>
          <Col
            className="menu order-1 order-md-0 flex-md-grow-1"
            xs={12}
            md={5}
          >
            <ul>
              <li>
                <Link to="/" onClick={() => setIsOpen(!isOpen)}>
                  Home
                </Link>
              </li>
              <li>
                <a
                  href="/#pricing-pro"
                  onClick={() => {
                    setIsOpen(!isOpen);
                    window.dispatchEvent(new Event('hashchange'));
                  }}
                >
                  Pricing
                </a>
              </li>
              <li>
                <Link to={routes.EDUCATIONAL_CORNER}>Blog</Link>
              </li>
              <li>
                <Link to={routes.FAQ}>FAQ</Link>
              </li>
            </ul>
          </Col>
          <Col className="login order-2 order-md-1 col-md-auto" xs={12}>
            <ButtonNew variant="ghost" size="small" onClick={appUrls.HOME}>
              Log in
            </ButtonNew>
          </Col>
          <Col className="register order-0 order-md-2 col-md-auto" xs={12}>
            <ButtonNew size="small" onClick={appUrls.REGISTER}>
              Sign up for free
            </ButtonNew>
          </Col>
          <Col className="register order-0 order-md-2 col-md-auto" xs={12}>
            <LanguageDropdown />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TopBar;
