import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { Card, Icon, Text } from '../../../components';

import './index.scss';

const Intro = () => {
  return (
    <Row className="short-about" noGutters>
      <Col xs={{ offset: 1, span: 10 }}>
        <Row className="short-about-cards">
          <Col xs={12} sm={6} md={4}>
            <Card>
              <Card.Badge>
                <Icon name="like" />
              </Card.Badge>
              <Text as="h3">
                <Localize id="homePage.SectionShortAbout.Title1" />
              </Text>
              <Text>
                <Localize id="homePage.SectionShortAbout.Description1" />
              </Text>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Card>
              <Card.Badge>
                <Icon name="devices" />
              </Card.Badge>
              <Text as="h3">
                <Localize id="homePage.SectionShortAbout.Title2" />
              </Text>
              <Text>
                <Localize id="homePage.SectionShortAbout.Description2" />
              </Text>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Card>
              <Card.Badge>
                <Icon name="puzzle" />
              </Card.Badge>
              <Text as="h3">
                <Localize id="homePage.SectionShortAbout.Title3" />
              </Text>
              <Text>
                <Localize id="homePage.SectionShortAbout.Description3" />
              </Text>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Intro;
