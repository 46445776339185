import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import headerKoko from '../../../images/new-landing/header-koko.svg';
import headerHero from '../../../images/new-landing/header-hero.png';

import appUrls from '../../../constants/appUrls';
import emoji from '../../../constants/emoji';

import { Text, ButtonNew } from '../../../components';

import './index.scss';

const Header = () => {
  return (
    <Row className="section-header" noGutters>
      <Col
        className="section-header-title"
        md={{ offset: 1, span: 4 }}
        sm={{ offset: 1, span: 10 }}
      >
        <img src={headerKoko} alt="Koko" />
        <Text as="h1" light bold color="light">
          <Localize id="homePage.SectionHeader.Title" />
        </Text>
        <Text as="h3" light color="light">
          <Localize id="homePage.SectionHeader.Description" />
        </Text>
        <ButtonNew onClick={appUrls.HOME}>
          <Localize id="homePage.SectionHeader.CTA" /> {emoji.POINTING_FINGER}
        </ButtonNew>
      </Col>
      <Col
        className="section-header-hero"
        md={{ offset: 1, span: 5 }}
        sm={{ offset: 1, span: 10 }}
      >
        <img src={headerHero} alt="" />
      </Col>
    </Row>
  );
};

export default Header;
