import React from 'react';
import { Link } from 'gatsby';
import { Localize } from '@kokolingo/localisation';
import scrollTo from 'gatsby-plugin-smoothscroll';
import routes from '../../routes';
import { LanguageSelect } from '..';

function setTabParent() {
  window.location.hash = '#cijene-za-roditelje';
  scrollTo('cijene-za-roditelje');
}

function setTabFacility() {
  window.location.hash = '#cijene-za-ustanove';
  scrollTo('cijene-za-ustanove');
}

export default [
  <Link
    className="nav-link"
    activeClassName="nav-item active"
    id="nav-parent"
    to={routes.PARENTS_ANCHOR}
    onClick={setTabParent}
  >
    <span className="nav-link">
      <Localize id="navbar.ParentsPricing" />
    </span>
  </Link>,

  <Link
    className="nav-link"
    activeClassName="nav-item active"
    id="nav-facility"
    to={routes.FACILITIES_ANCHOR}
    onClick={setTabFacility}
  >
    <span className="nav-link">
      <Localize id="navbar.FacilitiesPricing" />
    </span>
  </Link>,

  <Link
    className="nav-link"
    activeClassName="nav-item active"
    to={routes.EDUCATIONAL_CORNER}
  >
    <span className="nav-link">
      <Localize id="navbar.EducationalCorner" />
    </span>
  </Link>,

  <Link className="nav-link" activeClassName="nav-item active" to={routes.FAQ}>
    <span className="nav-link">
      <Localize id="navbar.FAQ" />
    </span>
  </Link>,

  <LanguageSelect />,
];
