import React from 'react';
import { Col, Row } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import { Localize } from '@kokolingo/localisation';

import howToUseHero from '../../../images/new-landing/how-to-use-hero.png';
import appUrls from '../../../constants/appUrls';
import emoji from '../../../constants/emoji';

import { Text, ButtonNew } from '../../../components';
import { qrAppLogin } from '../../../images';

import './index.scss';

const Intro = () => {
  return (
    <Row className="section-how-to-start" noGutters>
      <Col
        xs={{ offset: 1, span: 10 }}
        md={{ offset: 1, span: 5 }}
        className="section-how-to-start-hero"
      >
        <LazyLoad classNamePrefix="section-how-to-start-hero" offset={100} once>
          <div className="image-stack">
            <div className="image-stack__item image-stack__item--top">
              <img src={qrAppLogin} alt="" />
            </div>
            <div className="image-stack__item image-stack__item--bottom">
              <img src={howToUseHero} alt="" />
            </div>
          </div>
        </LazyLoad>
      </Col>
      <Col xs={{ offset: 1, span: 10 }} md={{ offset: 0, span: 5 }}>
        <Text as="h2" bold>
          <Localize id="homePage.SectionHowToStart.Title" />
        </Text>
        <ol>
          <li>
            <Text>
              <Localize
                id="homePage.SectionHowToStart.Note1"
                values={{
                  a: (chunk) => <a href={appUrls.HOME}>{chunk}</a>,
                }}
              />
            </Text>
          </li>
          <li>
            <Text>
              <Localize
                id="homePage.SectionHowToStart.Note2"
                values={{
                  a: (value) => (
                    <a
                      className="text__primary font-weight-bold"
                      target="_blank"
                      href={appUrls.HOME}
                      rel="noreferrer"
                    >
                      {value}
                    </a>
                  ),
                }}
              />
            </Text>
          </li>
          <li>
            <Text>
              <Localize id="homePage.SectionHowToStart.Note3" />
            </Text>
          </li>
        </ol>
        <ButtonNew onClick="/#pricing">
          <Localize id="homePage.SectionHowToStart.CTA" />{' '}
          {emoji.POINTING_FINGER}
        </ButtonNew>
      </Col>
    </Row>
  );
};

export default Intro;
