import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { FAQItems } from '../../index';
import routes from '../../../routes';

import { Text, ButtonNew } from '../../../components';
import FAQnew from '../FAQnew';

import './index.scss';

const SectionFaq = () => {
  const homeFaq = FAQItems.slice(0, 7);
  return (
    <Row className="section-faq" noGutters>
      <Col md={{ offset: 2, span: 8 }} xs={{ offset: 1, span: 10 }}>
        <Text as="h2" bold center>
          <Localize id="homePage.SectionFAQ.Title" />
        </Text>
        <FAQnew answers={homeFaq} />
        <ButtonNew onClick={routes.FAQ}>
          <Localize id="homePage.SectionFAQ.CTA" />
        </ButtonNew>
      </Col>
    </Row>
  );
};

export default SectionFaq;
