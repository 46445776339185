import React from 'react';
import PropTypes from 'prop-types';

import { VideoThumbnail } from '../../images';

import './index.css';

const BlogVideo = ({ poster, title, src }) => {
  return (
    <div className="block__video-container">
      <video poster={poster} title={title} controls>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

BlogVideo.propTypes = {
  poster: PropTypes.string,
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

BlogVideo.defaultProps = {
  poster: VideoThumbnail,
};

export default BlogVideo;
