import React from 'react';
import { Localize } from '@kokolingo/localisation';

import attrs from './attributes';

import eGlasBroj from '../../images/eglas_broj.svg';
import kokolingoFacebook from '../../images/kokolingo_facebook.svg';
import kokolingoInstagram from '../../images/kokolingo_instagram.svg';
import kokolingoMail from '../../images/kokolingo_mail.svg';
import kokolingoYoutube from '../../images/kokolingo_youtube.svg';

const CONTACTS = [
  <a href={attrs.facebook} target="_blank" rel="noopener noreferrer">
    <Localize.Img
      src={kokolingoFacebook}
      titleID="footer.Facebook"
      altID="footer.Facebook"
      className="world"
    />
  </a>,

  <a href={attrs.instagram} target="_blank" rel="noopener noreferrer">
    <Localize.Img
      src={kokolingoInstagram}
      titleID="footer.Instagram"
      altID="footer.Instagram"
      className="world"
    />
  </a>,

  <a href={attrs.youtube} target="_blank" rel="noopener noreferrer">
    <Localize.Img
      src={kokolingoYoutube}
      titleID="footer.Youtube"
      altID="footer.Youtube"
      className="world"
    />
  </a>,

  <a href={attrs.mail}>
    <Localize.Img
      src={kokolingoMail}
      titleID="footer.Mail"
      altID="footer.Mail"
      className="world"
    />
  </a>,

  <a href={attrs.phone}>
    <Localize.Img
      src={eGlasBroj}
      titleID="footer.Telephone"
      altID="footer.Telephone"
      className="world"
    />
  </a>,
];

export default { CONTACTS };
