import { useEffect, useState } from 'react';

// https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx
const useScript = (attributes, appendNodeName = 'head') => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');

    Object.keys(attributes).forEach((key) => {
      script.setAttribute(key, attributes[key]);
    });

    script.async = true;
    script.addEventListener('load', () => setScriptLoaded(true));

    document[appendNodeName].appendChild(script);

    return () => {
      document[appendNodeName].removeChild(script);
    };
  }, []);

  return { scriptLoaded };
};

export default useScript;
