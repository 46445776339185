import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const Text = ({
  children,
  as,
  color,
  center,
  bold,
  className,
  style,
  size,
  ellipsis,
}) => {
  const classNames = cn(
    'text',
    `text__${color}`,
    `text__${size}`,
    {
      'text-center': center,
      'font-weight-bold': bold,
      text__ellipsis: ellipsis,
    },
    className
  );
  const Component = as;

  return (
    <Component className={classNames} style={style}>
      {children}
    </Component>
  );
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  center: PropTypes.bool,
  bold: PropTypes.bool,
  color: PropTypes.oneOf(['light', 'primary']),
  size: PropTypes.oneOf(['regular', 'small', 'large']),
  ellipsis: PropTypes.bool,
};

Text.defaultProps = {
  as: 'span',
  color: undefined,
  bold: false,
  ellipsis: false,
  center: false,
  className: '',
  style: {},
  size: 'regular',
};

export default Text;
