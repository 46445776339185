import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Text from '../Text';

import './index.scss';

const AshaHeader = ({ className }) => {
  return (
    <Row className={`header1 ${className}`} noGutters>
      <Col sm={{ offset: 1, span: 10 }}>
        <Text as="h1" color="light" bold>
          ASHA2022
        </Text>
      </Col>
    </Row>
  );
};

AshaHeader.propTypes = {
  className: PropTypes.string,
};

AshaHeader.defaultProps = {
  className: '',
};

export default AshaHeader;
