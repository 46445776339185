import React from 'react';

import './index.css';

const OnlineWebinars = () => {
  return (
    <>
      <div className="online-webinar-block--card description">
        <p>
          Artikulacijski poremećaji jedni su od najčešćih govornih poremećaja
          djece predškolske dobi. Kako bi osvijestili javnost oko njihove
          problematike odlučili smo organizirati webinare za roditelje i
          webinare za stručnjake.
        </p>
      </div>
      <div className="online-webinar-block--card">
        <p>
          Webinar za <b>roditelje</b>, održan 5.11.2020.
        </p>
        <a
          href="https://www.youtube.com/watch?v=yVWao6Pzc0c"
          title="Webinar za roditelje koji je održan 5.11.2020."
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.youtube.com/watch?v=yVWao6Pzc0c
        </a>
      </div>
      <div className="online-webinar-block--card">
        <p>
          Webinar za <b>stručnjake</b>, održan 4.11.2020.
        </p>
        <a
          href="https://www.youtube.com/watch?v=2ZQQ51c9UnU"
          title="Webinar za stručnjake koji je održan 4.11.2020."
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.youtube.com/watch?v=2ZQQ51c9UnU
        </a>
      </div>
    </>
  );
};

export default OnlineWebinars;
