import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Text from '../Text';

import Arrow from './arrow';

const Navigation = ({ index, count, onChange }) => {
  const isLeftMost = index === 1;
  const isRightMost = index === count;

  const leftClassName = cn('arrow', 'left', { disabled: isLeftMost });
  const rightClassName = cn('arrow', 'right', { disabled: isRightMost });

  return (
    <div className="activity-navigation">
      <span
        className={leftClassName}
        onClick={() => !isLeftMost && onChange(index - 1)}
      >
        <Arrow />
      </span>
      <Text>{index}</Text>
      <span
        className={rightClassName}
        onClick={() => !isRightMost && onChange(index + 1)}
      >
        <Arrow />
      </span>
    </div>
  );
};

Navigation.propTypes = {
  index: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Navigation;
