import React from 'react';
import { PropTypes } from 'prop-types';
import { Row, Tabs, Tab } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { Text } from '../../../components';

import kokoTheParrotPremium from '../../../images/premium_papiga_koko.svg';
import kokoTheParrotSmart from '../../../images/smart_papiga_koko.svg';
import kokoTheParrotMini from '../../../images/mini_papiga_koko.svg';

import './index.scss';

const packages = [
  { parrotImage: kokoTheParrotPremium, packageLabel: 'Premium', id: 'premium' },
  { parrotImage: kokoTheParrotSmart, packageLabel: 'Smart', id: 'smart' },
  { parrotImage: kokoTheParrotMini, packageLabel: 'Mini', id: 'mini' },
];

const SectionPricing = ({ children: PricingPackage }) => {
  return (
    <Row className="section-pricing" noGutters>
      <div id="pricing" />
      <div className="pricing-title-description">
        <Text as="h3" color="light" bold>
          <Localize id="pricing.Title" />
        </Text>
        <Text as="p" center color="light">
          <Localize id="pricing.Description" />
        </Text>
      </div>
      <div className="fade show">
        <div className="packages-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 block4-pricing-container">
                {PricingPackage({
                  parrotImage: kokoTheParrotPremium,
                  packageLabel: 'Premium',
                })}
              </div>
              <div className="col-md-4 block4-pricing-container">
                {PricingPackage({
                  parrotImage: kokoTheParrotSmart,
                  packageLabel: 'Smart',
                })}
              </div>
              <div className="col-md-4 block4-pricing-container">
                {PricingPackage({
                  parrotImage: kokoTheParrotMini,
                  packageLabel: 'Mini',
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="packages-container-mobile">
          <Tabs
            defaultActiveKey={packages[0].id}
            className="justify-content-center tab-styling"
            style={{ height: '39.5px' }}
          >
            {packages.map(({ packageLabel, parrotImage, id }) => (
              <Tab
                key={id}
                eventKey={id}
                id={id}
                title={id}
                className="tab-pane"
              >
                <div id={id} className="nav-link fade in active show">
                  {PricingPackage({
                    parrotImage,
                    packageLabel,
                  })}
                </div>
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
      <div className="pricing-title-description pricing-explanation">
        <b>
          <span style={{ color: '#cc200a' }}>**</span>
        </b>{' '}
        <Localize id="pricing.IndexMotivationDescription" />
      </div>
    </Row>
  );
};

SectionPricing.propTypes = {
  children: PropTypes.func.isRequired,
};

export default SectionPricing;
