import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import './index.scss';

const YouTubeVideo = ({ videoId, videoImgUrl }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const videoUrl = `https://www.youtube.com/embed/${videoId}/?autoplay=1&amp;rel=0&amp;playsinline=1`;

  const youTubeVideoImgUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  const finalVideoImgUrl = videoImgUrl || youTubeVideoImgUrl;

  return (
    <>
      {isLoaded ? (
        <iframe
          className="youtube-video"
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; playsinline; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      ) : (
        <div className="youtube-placeholder" onClick={() => setIsLoaded(true)}>
          <img
            className="youtube-placeholder__image"
            src={finalVideoImgUrl}
            alt="Kokolingo u medijima"
          />
          <Icon name="play" />
        </div>
      )}
    </>
  );
};

YouTubeVideo.propTypes = {
  videoId: PropTypes.string.isRequired,
  videoImgUrl: PropTypes.string,
};

YouTubeVideo.defaultProps = {
  videoImgUrl: undefined,
};

export default YouTubeVideo;
