import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { BlogLastArticles } from '../../index';
import routes from '../../../routes';

import { Text, ButtonNew } from '../../../components';

import './index.scss';

const SectionBlog = () => {
  return (
    <Row className="section-blog" noGutters>
      <Col
        sm={{ offset: 1, span: 10 }}
        md={{ offset: 2, span: 8 }}
        xs={{ offset: 1, span: 10 }}
      >
        <Text as="h2" bold center>
          <Localize id="homePage.SectionBlog.Title" />
        </Text>
        <BlogLastArticles />
        <ButtonNew onClick={routes.EDUCATIONAL_CORNER}>
          <Localize id="homePage.SectionBlog.CTA" />
        </ButtonNew>
      </Col>
    </Row>
  );
};

export default SectionBlog;
