import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import './index.scss';

const Header = ({ children, className }) => {
  return (
    <Row className={`header ${className}`} noGutters>
      <Col sm={{ offset: 1, span: 10 }}>{children}</Col>
    </Row>
  );
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Header.defaultProps = {
  className: '',
};

export default Header;
