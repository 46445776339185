import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { shareCover } from '../images';
import favicon from '../images/favicon.svg';

import { useHtmlTag, useScript } from '../utils';

const Head = ({ title, description, siteUrl }) => {
  useHtmlTag({
    tagName: 'div',
    attributes: {
      id: 'krakencb-3697cf0f-126f-4add-a9aa-b4e764799da8',
    },
  });

  useScript(
    {
      src:
        'https://cdn.krakenoptimize.com/chatbot/GetCb?kraken_cb_a=88cd165c-cbee-4696-bab0-08da5db85be8&kraken_cb_p=3697cf0f-126f-4add-a9aa-b4e764799da8',
    },
    'body'
  );

  return (
    <Helmet
      bodyAttributes={{
        'data-spy': 'scroll',
        'data-offset': '100',
        'data-target': '#navbar',
      }}
    >
      <title>{title}</title>

      {/* Primary meta tags */}
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      {/* Open Graph / Facebook */}
      <meta
        name="facebook-domain-verification"
        content="hj9jw4xt87vnl8ci9ip1o6ieonwqt4"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={shareCover} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={siteUrl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={shareCover} />

      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="initial-scale=1.0, maximum-scale=1.0, width=device-width, user-scalable=no"
      />
      <meta property="og:image" content={shareCover} />
      <link rel="shortcut icon" href={favicon} type="image/x-icon" />
    </Helmet>
  );
};

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  siteUrl: PropTypes.string,
};

Head.defaultProps = {
  title: 'Kokolingo',
  siteUrl: 'kokolingo.hr',
};

export default Head;
