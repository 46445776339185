import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.css';

const BlogImage = ({ className, imgAlt, imgTitle, imgSrc }) => {
  const blogImageClassName = cn('blog__image', className);

  return (
    <img
      loading="lazy"
      alt={imgAlt}
      title={imgTitle}
      className={blogImageClassName}
      src={imgSrc}
      onClick={() => {
        window.open(imgSrc);
      }}
    />
  );
};

BlogImage.propTypes = {
  className: PropTypes.string,
  imgAlt: PropTypes.string.isRequired,
  imgTitle: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
};

BlogImage.defaultProps = {
  className: '',
};

export default BlogImage;
