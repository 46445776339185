import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const Card = ({ children, size, className }) => {
  const cardClassName = cn('card', `card__${size}`, className);
  return <div className={cardClassName}>{children}</div>;
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['large', 'small']),
  className: PropTypes.string,
};

Card.defaultProps = {
  size: 'small',
  className: '',
};

const Badge = ({ children }) => (
  <>
    <div className="card-badge">{children}</div>
    <div className="card-badge-clearfix" />
  </>
);

Badge.propTypes = {
  children: PropTypes.node.isRequired,
};

Card.Badge = Badge;
export default Card;
