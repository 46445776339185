import React from 'react';

import kokolingoIsland from '../hr/images/kokolingo_otok.png';
import pirateSrecko from '../hr/images/gusar_srecko.png';
import carefulListeningBeginning from '../hr/images/pazljivo_slusanje_pocetna.png';
import carefulListeningGame from '../hr/images/pazljivo_slusanje_igra.png';
import logatomsGame from '../hr/images/cudnovate_rijeci_igra.png';
import findTheWordBeginning from '../hr/images/pronadi_rijec_pocetna.png';
import wordsGame from '../hr/images/rijeci_igra.png';
import memoryGame from '../hr/images/memori_igra.png';
import sentencesGame from '../hr/images/recenice_igra.png';
import storiesBeginning from '../hr/images/price_pocetna.png';

export default [
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={kokolingoIsland}
      title="Interactive Kokolingo map"
      alt="Interactive Kokolingo map"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={pirateSrecko}
      title="Lucky the Pirate"
      alt="Lucky the Pirate"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={carefulListeningBeginning}
      title="Game of careful listening"
      alt="Game of careful listening"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={carefulListeningGame}
      title="Choose the correct voice"
      alt="Choose the correct voice"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={logatomsGame}
      title="Funny words or logatoms"
      alt="Funny words or logatoms"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={findTheWordBeginning}
      title="Find the word"
      alt="Find the word"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={wordsGame}
      title="Words"
      alt="Words"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={memoryGame}
      title="Memory"
      alt="Memory"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={sentencesGame}
      title="Sentences"
      alt="Sentences"
    />
  </div>,
  <div>
    <img
      loading="lazy"
      className="slide-img"
      src={storiesBeginning}
      title="Let's read the stories"
      alt="Let's read the stories"
    />
  </div>,
];
