import React from 'react';
import { Col, Row } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import { Localize } from '@kokolingo/localisation';

import collage from '../../../images/new-landing/intro-collage.jpg';

import { Text } from '../../../components';

import './index.scss';

const Intro = () => {
  return (
    <Row className="intro" noGutters>
      <Col
        className="intro-collage order-1 order-sm-0"
        sm={{ offset: 1, span: 5 }}
        xs={{ offset: 1, span: 10 }}
      >
        <LazyLoad classNamePrefix="intro-collage" offset={100} once>
          <img src={collage} alt="" />
        </LazyLoad>
      </Col>
      <Col
        sm={{ offset: 0, span: 5 }}
        xs={{ offset: 1, span: 10 }}
        className="order-0 order-sm-1"
      >
        <Text as="h3">
          <Localize id="homePage.SectionIntro.Title" />
        </Text>
        <Text>
          <Localize id="homePage.SectionIntro.Description" />
        </Text>
      </Col>
    </Row>
  );
};

export default Intro;
