import React from 'react';
import { Helmet } from 'react-helmet';

const GameFragment = () => {
  return (
    <Helmet>
      <script
        src="https://kokolingo.activehosted.com/f/embed.php?id=3"
        type="text/javascript"
        charSet="utf-8"
      />
    </Helmet>
  );
};

export default GameFragment;
