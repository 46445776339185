import React, { useEffect, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Localize } from '@kokolingo/localisation';

import routes from '../../routes';

import activityTypesItems from '../../constants/activityTypesItems';
import additionalBenefitsItems from '../../constants/additionalBenefitsItems';

import { html } from '../../utils';

import kokoTheParrotPremium from '../../images/premium_papiga_koko.svg';
import kokoTheParrotSmart from '../../images/smart_papiga_koko.svg';
import kokoTheParrotMini from '../../images/mini_papiga_koko.svg';
import kokoTheParrotFacilities25SVG from '../../images/papiga_koko_ustanove_25.svg';
import kokoTheParrotFacilities10SVG from '../../images/papiga_koko_ustanove_10.svg';
import kokoTheParrotFacilities5SVG from '../../images/papiga_koko_ustanove_5.svg';
import kokoTheParrotFacilities25PNG from '../../images/papiga_koko_ustanove_25.png';
import kokoTheParrotFacilities10PNG from '../../images/papiga_koko_ustanove_10.png';
import kokoTheParrotFacilities5PNG from '../../images/papiga_koko_ustanove_5.png';

import { PricingItems, Button } from '../../components';

const PricingTable = () => {
  const [activeKey, setActiveKey] = useState('parents');

  function hashChange() {
    if (window.location.hash === html.anchorUrl(routes.PARENTS_ANCHOR)) {
      setActiveKey('parents');
    } else if (
      window.location.hash === html.anchorUrl(routes.FACILITIES_ANCHOR)
    ) {
      setActiveKey('institutes');
    }
  }

  useEffect(() => {
    window.addEventListener('hashchange', hashChange);

    hashChange();

    return () => {
      window.removeEventListener('hashchange', hashChange);
    };
  }, [activeKey]);

  return (
    <Tabs
      activeKey={activeKey}
      onSelect={() => {
        setActiveKey();
        // eslint-disable-next-line no-restricted-globals
        history.pushState('', document.title, window.location.pathname);
      }}
      className="nav nav-item parent justify-content-center tab-styling"
    >
      <Tab
        eventKey="parents"
        id="nav-tab-parent"
        title={<Localize id="pricing.ParentsTitle" />}
        className="tab-pane"
      >
        <div id="parents" className="fade show">
          <div className="packages-container">
            <p>
              <Localize id="pricing.KidsPacket" />
            </p>

            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 block4-pricing-container">
                  <div className="pricing-container">
                    <h1 className="pricing-title pricing-title-bg">
                      <Localize id="pricing.Premium" />
                    </h1>
                    <img
                      className="pricing-parrot"
                      alt="parrot1"
                      width="170"
                      height="250"
                      src={kokoTheParrotPremium}
                    />
                    <Button className="button-test" link="/register/client">
                      <Localize id="pricing.TryForFree" />
                    </Button>
                    <div className="pricing-benefits pricing-text">
                      <p style={{ paddingRight: '17%' }}>
                        <Localize id="pricing.UnlimitedUse" />
                      </p>
                      <p>
                        <Localize id="pricing.IndexMotivation" />
                        <b>
                          <span style={{ color: '#cc200a' }}>** </span>
                          60
                        </b>
                      </p>
                      <p>
                        <b>
                          <Localize id="pricing.TypesOfActivities" />
                        </b>
                      </p>
                      <PricingItems packages={activityTypesItems.PREMIUM} />
                      <p>
                        <b>
                          <Localize id="pricing.AdditionalBenefits" />
                        </b>
                      </p>
                      <PricingItems
                        packages={additionalBenefitsItems.PREMIUM}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 block4-pricing-container">
                  <div className="pricing-container">
                    <div className="pricing-main">
                      <h1 className="pricing-title smart">
                        <Localize id="pricing.Smart" />
                      </h1>
                      <img
                        className="pricing-parrot"
                        alt="parrot1"
                        width="170"
                        height="250"
                        src={kokoTheParrotSmart}
                      />
                      <Button
                        className="button-test smart filler"
                        link="/register/client"
                      >
                        <Localize id="pricing.TryForFree" />
                      </Button>
                    </div>
                    <div className="pricing-benefits pricing-text">
                      <p style={{ paddingRight: '17%' }}>
                        <Localize id="pricing.UnlimitedUse" />
                      </p>
                      <p>
                        <Localize id="pricing.IndexMotivation" />
                        <b>
                          <span style={{ color: '#cc200a' }}>** </span>
                          30
                        </b>
                      </p>
                      <p>
                        <b>
                          <Localize id="pricing.TypesOfActivities" />
                        </b>
                      </p>
                      <PricingItems packages={activityTypesItems.SMART} />
                      <p>
                        <b>
                          <Localize id="pricing.AdditionalBenefits" />
                        </b>
                      </p>
                      <PricingItems packages={additionalBenefitsItems.SMART} />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 block4-pricing-container">
                  <div className="pricing-container">
                    <h1 className="pricing-title pricing-title-bg">
                      <Localize id="pricing.Mini" />
                    </h1>
                    <img
                      className="pricing-parrot"
                      alt="parrot1"
                      width="170"
                      height="250"
                      src={kokoTheParrotMini}
                    />
                    <Button
                      className="button-test smart filler"
                      link="/register/client"
                    >
                      <Localize id="pricing.TryForFree" />
                    </Button>
                    <div className="pricing-benefits pricing-text">
                      <p>
                        <Localize id="pricing.LimitedUse" />
                      </p>
                      <p>
                        <Localize id="pricing.IndexMotivation" />
                        <b>
                          <span style={{ color: '#cc200a' }}>** </span>
                          15
                        </b>
                      </p>
                      <p>
                        <b>
                          <Localize id="pricing.TypesOfActivities" />
                        </b>
                      </p>
                      <PricingItems packages={activityTypesItems.MINI} />
                      <p>
                        <b>
                          <Localize id="pricing.AdditionalBenefits" />
                        </b>
                      </p>
                      <PricingItems packages={additionalBenefitsItems.MINI} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="pricing-text" style={{ paddingBottom: '0' }}>
              <b>
                <span style={{ color: '#cc200a' }}>**</span>
              </b>{' '}
              <Localize id="pricing.IndexMotivationDescription" />
            </p>
          </div>
          <div className="packages-container-mobile">
            <p className="pricing-text tab">
              <Localize
                id="pricing.KidsPacket"
                values={{
                  span: (freeTrial) => (
                    <span
                      className="pricing-text free-trial"
                      style={{ color: '#cc200a' }}
                    >
                      {freeTrial}
                    </span>
                  ),
                }}
              />
            </p>
            <Tabs
              defaultActiveKey="premium"
              className="justify-content-center tab-styling"
              style={{ height: '39.5px' }}
            >
              <Tab
                eventKey="premium"
                id="premium"
                title="PREMIUM"
                className="tab-pane"
                flex-wrap="nowrap"
              >
                <div id="premium" className="nav-link fade in active show">
                  <div className="pricing-container">
                    <h1 className="pricing-title pricing-title-bg">
                      <Localize id="pricing.Premium" />
                    </h1>
                    <img
                      className="pricing-parrot"
                      alt="parrot1"
                      width="170"
                      height="250"
                      src={kokoTheParrotPremium}
                    />
                    <Button className="button-test" link="/register/client">
                      <Localize id="pricing.TryForFree" />
                    </Button>
                    <div className="pricing-benefits pricing-text">
                      <p>
                        <Localize id="pricing.UnlimitedUse" />
                      </p>
                      <p>
                        <Localize id="pricing.IndexMotivation" />
                        <b>
                          <span style={{ color: '#cc200a' }}>** </span>
                          60
                        </b>
                      </p>
                      <p>
                        <b>
                          <Localize id="pricing.TypesOfActivities" />
                        </b>
                      </p>
                      <PricingItems packages={activityTypesItems.PREMIUM} />
                      <p>
                        <b>
                          <Localize id="pricing.AdditionalBenefits" />
                        </b>
                      </p>
                      <PricingItems
                        packages={additionalBenefitsItems.PREMIUM}
                      />
                    </div>
                  </div>
                  <p className="pricing-text" style={{ padding: '2% 4%' }}>
                    <b>
                      <span style={{ color: '#cc200a' }}>**</span>
                    </b>{' '}
                    <Localize id="pricing.IndexMotivationDescription" />
                  </p>
                </div>
              </Tab>
              <Tab
                eventKey="smart"
                id="smart"
                title="SMART"
                className="tab-pane"
              >
                <div id="smart" className="nav-link fade in active show">
                  <div className="pricing-container parent">
                    <div className="pricing-main">
                      <h1 className="pricing-title smart">
                        <Localize id="pricing.Smart" />
                      </h1>
                      <img
                        className="pricing-parrot"
                        alt="parrot1"
                        width="170"
                        height="250"
                        src={kokoTheParrotSmart}
                      />
                      <Button
                        className="button-test smart filler"
                        link="/register/client"
                      >
                        <Localize id="pricing.TryForFree" />
                      </Button>
                    </div>
                    <div className="pricing-benefits pricing-text">
                      <p>
                        <Localize id="pricing.UnlimitedUse" />
                      </p>
                      <p>
                        <Localize id="pricing.IndexMotivation" />
                        <b>
                          <span style={{ color: '#cc200a' }}>** </span>
                          30
                        </b>
                      </p>
                      <p>
                        <b>
                          <Localize id="pricing.TypesOfActivities" />
                        </b>
                      </p>
                      <PricingItems packages={activityTypesItems.SMART} />
                      <p>
                        <b>
                          <Localize id="pricing.AdditionalBenefits" />
                        </b>
                      </p>
                      <PricingItems packages={additionalBenefitsItems.SMART} />
                    </div>
                  </div>
                  <p className="pricing-text" style={{ padding: '2% 4%' }}>
                    <b>
                      <span style={{ color: '#cc200a' }}>**</span>
                    </b>{' '}
                    <Localize id="pricing.IndexMotivationDescription" />
                  </p>
                </div>
              </Tab>
              <Tab eventKey="mini" id="mini" title="MINI" className="tab-pane">
                <div id="mini" className="nav-link fade in active show">
                  <div className="pricing-container">
                    <h1 className="pricing-title pricing-title-bg">
                      <Localize id="pricing.Mini" />
                    </h1>
                    <img
                      className="pricing-parrot"
                      alt="parrot1"
                      width="170"
                      height="250"
                      src={kokoTheParrotMini}
                    />
                    <Button className="button-test" link="/register/client">
                      <Localize id="pricing.TryForFree" />
                    </Button>
                    <div className="pricing-benefits pricing-text">
                      <p>
                        <Localize id="pricing.LimitedUse" />
                      </p>
                      <p>
                        <Localize id="pricing.IndexMotivation" />
                        <b>
                          <span style={{ color: '#cc200a' }}>** </span>
                          15
                        </b>
                      </p>
                      <p>
                        <b>
                          <Localize id="pricing.TypesOfActivities" />
                        </b>
                      </p>
                      <PricingItems packages={activityTypesItems.MINI} />
                      <p>
                        <b>
                          <Localize id="pricing.AdditionalBenefits" />
                        </b>
                      </p>
                      <PricingItems packages={additionalBenefitsItems.MINI} />
                    </div>
                  </div>
                  <p className="pricing-text" style={{ padding: '2% 4%' }}>
                    <b>
                      <span style={{ color: '#cc200a' }}>**</span>
                    </b>{' '}
                    <Localize id="pricing.IndexMotivationDescription" />
                  </p>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </Tab>
      <Tab
        eventKey="institutes"
        id="nav-tab-facility"
        title={<Localize id="pricing.FacilitiesTitle" />}
      >
        <div id="institutes" className="fade show">
          <div className="packages-container">
            <p>
              <Localize id="pricing.FacilitiesDescription" />
            </p>

            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 block4-pricing-container">
                  <div className="pricing-container institutes">
                    <h1 className="pricing-title pricing-title-bg">
                      KOKOLINGO 25
                    </h1>
                    <img
                      className="pricing-institutes-parrot"
                      width="250"
                      height="290"
                      alt="parrot1"
                      src={kokoTheParrotFacilities25SVG}
                    />
                    <div className="pricing-text-container">
                      <p className="pricing-text institutes">
                        <Localize
                          id="pricing.FacilitiesPremium"
                          values={{
                            b: (chunks) => <b>{chunks}</b>,
                          }}
                        />
                      </p>
                      <p className="pricing-text institutes">
                        <b className="black-bold">25</b>{' '}
                        <Localize id="pricing.FacilitiesProfiles" />
                        <b className="red-bold">**</b>
                      </p>
                      <p className="pricing-text institutes">
                        <Localize
                          id="pricing.FacilitiesWorksOn"
                          values={{
                            b: (chunks) => (
                              <b className="black-bold">{chunks}</b>
                            ),
                            number: '10',
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 block4-pricing-container">
                  <div className="pricing-container institutes">
                    <div className="pricing-main">
                      <h1 className="pricing-title">KOKOLINGO 10</h1>
                      <img
                        className="pricing-institutes-parrot smart"
                        width="250"
                        height="290"
                        alt="parrot1"
                        src={kokoTheParrotFacilities10SVG}
                      />
                    </div>
                    <div className="pricing-text-container smart">
                      <p className="pricing-text institutes">
                        <Localize
                          id="pricing.FacilitiesPremium"
                          values={{
                            b: (chunks) => <b>{chunks}</b>,
                          }}
                        />
                      </p>
                      <p className="pricing-text institutes">
                        <b className="black-bold">10</b>{' '}
                        <Localize id="pricing.FacilitiesProfiles" />
                        <b className="red-bold">**</b>
                      </p>
                      <p className="pricing-text institutes">
                        <Localize
                          id="pricing.FacilitiesWorksOn"
                          values={{
                            b: (chunks) => (
                              <b className="black-bold">{chunks}</b>
                            ),
                            number: '4',
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 block4-pricing-container">
                  <div className="pricing-container institutes">
                    <h1 className="pricing-title pricing-title-bg">
                      KOKOLINGO 5
                    </h1>
                    <img
                      className="pricing-institutes-parrot"
                      width="250"
                      height="290"
                      alt="parrot1"
                      src={kokoTheParrotFacilities5SVG}
                    />
                    <div className="pricing-text-container">
                      <p className="pricing-text institutes">
                        <Localize
                          id="pricing.FacilitiesPremium"
                          values={{
                            b: (chunks) => <b>{chunks}</b>,
                          }}
                        />
                      </p>
                      <p className="pricing-text institutes">
                        <b className="black-bold">5</b>{' '}
                        <Localize id="pricing.FacilitiesProfiles" />
                        <b className="red-bold">**</b>
                      </p>
                      <p className="pricing-text institutes">
                        <Localize
                          id="pricing.FacilitiesWorksOn"
                          values={{
                            b: (chunks) => (
                              <b className="black-bold">{chunks}</b>
                            ),
                            number: '2',
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p style={{ paddingBottom: '0' }} className="pricing-text">
              <b className="red-bold">*</b>{' '}
              <Localize id="pricing.OneTimePayment" />
            </p>
            <p
              style={{ paddingBottom: '0', paddingTop: '0' }}
              className="pricing-text"
            >
              <b className="red-bold">**</b> <Localize id="pricing.Empolyees" />
            </p>
            <p
              style={{ paddingBottom: '0', paddingTop: '0' }}
              className="pricing-text"
            >
              <Localize
                id="pricing.Contact"
                values={{
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />
            </p>
          </div>
          <div className="packages-container-mobile">
            <p className="pricing-text tab">
              <Localize id="pricing.FacilitiesDescription" />
            </p>
            <Tabs
              defaultActiveKey="koko25"
              className="justify-content-center tab-styling"
              style={{ height: '39.5px' }}
            >
              <Tab
                eventKey="koko25"
                id="koko25"
                title="KOKO 25"
                className="tab-pane"
                flex-wrap="nowrap"
              >
                <div id="koko25" className="nav-link fade in active show">
                  <div className="pricing-container institutes">
                    <h1 className="pricing-title pricing-title-bg institutes">
                      KOKOLINGO 25
                    </h1>
                    <img
                      className="pricing-institutes-parrot"
                      alt="parrot1"
                      width="170"
                      height="250"
                      src={kokoTheParrotFacilities25PNG}
                    />
                    <div className="price-text-container">
                      <p className="pricing-text institutes">
                        <Localize
                          id="pricing.FacilitiesPremium"
                          values={{
                            b: (chunks) => <b>{chunks}</b>,
                          }}
                        />
                      </p>
                      <p className="pricing-text institutes">
                        <b className="black-bold">25</b>{' '}
                        <Localize id="pricing.FacilitiesProfiles" />
                        <b className="red-bold">**</b>
                      </p>
                      <p className="pricing-text institutes">
                        <Localize
                          id="pricing.FacilitiesWorksOn"
                          values={{
                            b: (chunks) => (
                              <b className="black-bold">{chunks}</b>
                            ),
                            number: '10',
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="koko10"
                id="koko10"
                title="KOKO 10"
                className="tab-pane"
                flex-wrap="nowrap"
              >
                <div id="koko10" className="nav-link fade in active show">
                  <div className="pricing-container institutes">
                    <div className="pricing-main">
                      <h1 className="pricing-title institutes smart">
                        KOKOLINGO 10
                      </h1>
                      <img
                        className="pricing-institutes-parrot"
                        alt="parrot1"
                        width="170"
                        height="250"
                        src={kokoTheParrotFacilities10PNG}
                      />
                    </div>
                    <div className="price-text-container">
                      <p className="pricing-text institutes">
                        <Localize
                          id="pricing.FacilitiesPremium"
                          values={{
                            b: (chunks) => <b>{chunks}</b>,
                          }}
                        />
                      </p>
                      <p className="pricing-text institutes">
                        <b className="black-bold">10</b>{' '}
                        <Localize id="pricing.FacilitiesProfiles" />
                        <b className="red-bold">**</b>
                      </p>
                      <p className="pricing-text institutes">
                        <Localize
                          id="pricing.FacilitiesWorksOn"
                          values={{
                            b: (chunks) => (
                              <b className="black-bold">{chunks}</b>
                            ),
                            number: '4',
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="koko5"
                id="koko5"
                title="KOKO 5"
                className="tab-pane"
                flex-wrap="nowrap"
              >
                <div id="koko5" className="nav-link fade in active show">
                  <div className="pricing-container institutes">
                    <h1 className="pricing-title institutes pricing-title-bg">
                      KOKOLINGO 5
                    </h1>
                    <img
                      className="pricing-institutes-parrot"
                      alt="parrot1"
                      width="170"
                      height="250"
                      src={kokoTheParrotFacilities5PNG}
                    />
                    <div className="price-text-container">
                      <p className="pricing-text institutes">
                        <Localize
                          id="pricing.FacilitiesPremium"
                          values={{
                            b: (chunks) => <b>{chunks}</b>,
                          }}
                        />
                      </p>
                      <p className="pricing-text institutes">
                        <b className="black-bold">5</b>{' '}
                        <Localize id="pricing.FacilitiesProfiles" />
                        <b className="red-bold">**</b>
                      </p>
                      <p className="pricing-text institutes">
                        <Localize
                          id="pricing.FacilitiesWorksOn"
                          values={{
                            b: (chunks) => (
                              <b className="black-bold">{chunks}</b>
                            ),
                            number: '2',
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
            <p
              style={{ paddingTop: '5%', paddingBottom: '0' }}
              className="pricing-text tab"
            >
              <b className="red-bold">*</b>{' '}
              <Localize id="pricing.OneTimePayment" />
            </p>
            <p
              style={{ paddingBottom: '0', paddingTop: '0' }}
              className="pricing-text tab"
            >
              <b className="red-bold">**</b> <Localize id="pricing.Empolyees" />
            </p>
            <p
              style={{ paddingTop: '0', paddingBottom: '5%' }}
              className="pricing-text tab"
            >
              <Localize id="pricing.Contact" />
            </p>
          </div>
        </div>
      </Tab>
    </Tabs>
  );
};

export default PricingTable;
