import React from 'react';
import { Localize } from '@kokolingo/localisation';

import { Block2Card } from '../../components';

import redGem from '../../images/crveni_dragulj.png';
import goldCoin from '../../images/zlatni_novcic.png';
import blueGem from '../../images/plavi_dragulj.png';
import orangeGem from '../../images/narancasti_dragulj.png';
import purpleGem from '../../images/ljubicasti_dragulj.png';

export default (
  <>
    <div className="col-lg-4">
      <Block2Card
        title={<Localize id="blockCard.Title1" />}
        description={<Localize id="blockCard.Description1" />}
        img={
          <Localize.Img
            src={redGem}
            titleID="blockCard.ImageTitle1"
            altID="blockCard.ImageAlt1"
            className="block2-card-title-img"
          />
        }
      />
      <Block2Card
        title={<Localize id="blockCard.Title2" />}
        description={<Localize id="blockCard.Description2" />}
        img={
          <Localize.Img
            src={goldCoin}
            titleID="blockCard.ImageTitle2"
            altID="blockCard.ImageAlt2"
            className="block2-card-title-img"
          />
        }
      />
    </div>
    <div className="col-lg-4 block2-card-center">
      <Block2Card
        title={<Localize id="blockCard.Title3" />}
        description={<Localize id="blockCard.Description3" />}
        img={
          <Localize.Img
            src={blueGem}
            titleID="blockCard.ImageTitle3"
            altID="blockCard.ImageAlt3"
            className="block2-card-title-img"
          />
        }
      />
    </div>
    <div className="col-lg-4">
      <Block2Card
        title={<Localize id="blockCard.Title4" />}
        description={<Localize id="blockCard.Description4" />}
        img={
          <Localize.Img
            src={orangeGem}
            titleID="blockCard.ImageTitle4"
            altID="blockCard.ImageAlt4"
            className="block2-card-title-img"
          />
        }
      />
      <Block2Card
        title={<Localize id="blockCard.Title5" />}
        description={<Localize id="blockCard.Description5" />}
        img={
          <Localize.Img
            src={purpleGem}
            titleID="blockCard.ImageTitle5"
            altID="blockCard.ImageAlt5"
            className="block2-card-title-img"
          />
        }
      />
    </div>
  </>
);
