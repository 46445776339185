import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';

const Tag = ({ children, variant }) => {
  const className = cn('tag', `tag__${variant}`);

  return <span className={className}>{children}</span>;
};

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['primary', 'light']),
};

Tag.defaultProps = {
  variant: 'primary',
};

export default Tag;
