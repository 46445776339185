import React from 'react';
import { Localize } from '@kokolingo/localisation';
import Button from '../Button';

import kokolingoLogoTitleMobile from '../../images/kokolingo_logo_naslov_mobitel.svg';
import kokolingoLogoTitle from '../../images/kokolingo_logo_naslov.svg';

import './index.css';

const Logo = () => {
  return (
    <div className="logo-block">
      <div className="logo-block__container logo-block__container--mobile text-center">
        <Localize.Img
          src={kokolingoLogoTitleMobile}
          altID="homePage.Logo"
          titleID="homePage.Logo"
          className="logo-block__container--image img-fluid logo-block__container--mobile"
        />
        <Button variant="mobile">
          <Localize id="homePage.KokolingoButton" />
        </Button>
      </div>
      <div className="logo-block__container">
        <Button variant="header">
          <Localize id="homePage.KokolingoButton" />
        </Button>
        <Localize.Img
          src={kokolingoLogoTitle}
          altID="homePage.Logo"
          titleID="homePage.Logo"
          className="logo-block__container--image img-fluid"
        />
      </div>
    </div>
  );
};

export default Logo;
