import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const Input = ({
  id,
  placeholder,
  value,
  onChange,
  type,
  className,
  error,
}) => {
  const inputClassName = error ? cn('is-invalid', className) : className;

  return (
    <input
      id={id}
      type={type}
      value={value}
      className={inputClassName}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

const Append = ({ children }) => {
  return <div className="input-group-append">{children}</div>;
};

Input.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
};

Input.defaultProps = {
  id: undefined,
  placeholder: undefined,
  value: undefined,
  type: 'text',
  className: 'input',
  error: undefined,
};

Append.propTypes = {
  children: PropTypes.element.isRequired,
};

Input.Append = Append;
export default Input;
