import React from 'react';
import PropTypes from 'prop-types';

import './index.css';

const BlogTitle = ({ children }) => {
  return <h2 className="blog__title">{children}</h2>;
};

const BlogSubtitle = ({ children }) => {
  return <h4 className="blog__subtitle">{children}</h4>;
};

BlogTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

BlogSubtitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export { BlogTitle, BlogSubtitle };
