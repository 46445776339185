import React from 'react';
import PropTypes from 'prop-types';

import { Head } from '../../../components';
import NavigationBar from '../NavigationBar';
import { Footer } from '../..';

const Page = ({ children, title, description }) => {
  return (
    <>
      <Head
        title={title}
        description={description}
        siteUrl={process.env.GATSBY_SITE_URL}
      />
      <NavigationBar />
      {children}
      <hr className="end-line" />
      <Footer />
    </>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
};

Page.defaultProps = {
  children: null,
  title: 'Kokolingo',
};

export default Page;
