import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import { BlogImage, Text } from '../../components';

import { PageNew } from '..';

import BlogArticles from './blogLastArticles';

import FunnyWords from '../../images/new-landing/activities/default/funny_words.png';
import ListenCarefully from '../../images/new-landing/activities/default/listen_carefully.png';
import MakeASound from '../../images/new-landing/activities/default/make_a_sound.png';
import Map from '../../images/new-landing/activities/default/map.png';
import Sentences from '../../images/new-landing/activities/default/sentences.png';
import Stories from '../../images/new-landing/activities/default/stories.png';
import Words from '../../images/new-landing/activities/default/words.png';

const SpeechDisorderPage = () => {
  return (
    <PageNew
      title="KOKOLINGO - Online speech and language therapy"
      description=" A speech and language therapist or speech language pathologist is a
        licensed professional who specializes in the identification and
        treatment of communication disorders."
      headline="Online speech and language therapy"
    >
      <Text as="p">
        A speech and language therapist or speech language pathologist is a
        licensed professional who specializes in the identification and
        treatment of communication disorders. Speech and language therapy is
        traditionally conducted in face-to-face visits, but it has become more
        and more common since the beginning of the COVID-19 pandemic to attend
        speech therapy visits remotely. Remote visits are conducted via video
        conference programs like Zoom, Google Meet, or Doxy. To practice and
        correct identified speech and language skills, the speech and language
        therapist will select different materials – toys, puzzles, worksheets,
        etc., to address the goals and build on the skills of that individual
        child. Some of the most frequently encountered communication disorders
        are speech sound disorders. A speech sound disorder might present as an
        articulation disorder (where one specific sound is affected) or as a
        phonological disorder (where patterns of sounds are affected). These
        speech sound disorders result in speech that is difficult for others to
        understand because of distortions, substitutions, or omissions of
        certain speech sounds. Kokolingo was specifically designed to work on
        awareness and speech sound production using structured speech therapy
        exercises.
      </Text>
      <BlogImage imgAlt="Map" imgTitle="Map" imgSrc={Map} />
      <Text as="h2">Overview of Kokolingo</Text>
      <Text as="p">
        The correction of a speech sound disorder relies on sequenced mastery of
        multiple related skills. Therapeutic exercises for correct articulation
        of speech sounds are presented in several stages, which can overlap with
        each other. At the very beginning, the exercises should focus on
        foundational speech skills – auditory discrimination of speech sounds
        (listening to and differentiating similar basic sounds) and basic
        control of speech organs in isolated sound production. Once the child
        has acquired these skills, they can start to practice the same basic
        sounds but now in combination with other consonants and vowels in short
        1 syllable words or nonsensical single syllable sounds. Once a child has
        mastered the correct pronunciation of basic syllables and short words,
        accurate pronunciation will continue to become more automatized in
        longer sequences of sounds. The child will become comfortable embedding
        the target sound in phrases, sentences, and eventually, entire
        conversations. Kokolingo follows this therapeutic sequence of
        progression, from carefully laying the foundation to the mastery of
        skills. Kokolingo strategically scaffolds your child's ability to
        produce correct sounds in ever more challenging circumstances.
      </Text>
      <Text as="h2">Auditory Discrimination of Speech Sounds</Text>
      <Text as="p">
        Before targeting the correct production of a speech sound in speech
        therapy, it is important for your child to learn to discriminate similar
        speech sounds. Sounds like /s/ and /z/ or /t/ and /d/ can seem quite
        alike. The auditory discrimination of speech sounds is a prerequisite
        for eventual correct pronunciation of those sounds. In other words,
        awareness comes before ability. In Kokolingo, auditory discrimination
        activities are located at the first island, in a section called Listen
        Carefully. Auditory discrimination of speech sounds can be practiced in
        two activities – Treasure Hunt and Coloring Book. In each of these
        activities, the child practices careful listening to differentiate
        similar speech sounds. The general task is to decide which words contain
        one sound (/d/ for example) and which ones contain another similar sound
        (in this example, that would be /t/). The spoken words in these
        activities are reinforced with pictures. The child needs to click on and
        listen to the correct pronunciation of the word or speech sound that is
        being practiced. Each word can be repeated as many times as is needed
        for an accurate response. This listening exercise helps your child learn
        to discriminate similar speech sounds and serves as a foundation for all
        speech practice moving forward.
      </Text>
      <BlogImage
        imgAlt="Listen Carefully"
        imgTitle="Listen Carefully"
        imgSrc={ListenCarefully}
      />
      <Text as="h2">Control of Articulators</Text>
      <Text as="p">
        For the correct production of speech sounds, your child needs to be able
        to move their articulators (lips, tongue, teeth, and lower jaw) with
        precision and at will. To achieve this mastery, speech therapy must
        simplify initial practice to target the most basic mouth movements
        first. These are the elemental movements needed to produce consonants
        and vowels in isolation. Reflecting the many ways that we must move and
        sustain mouth positions for different speech sounds and combinations of
        sounds, we must intentionally practice both static exercises (in which
        the child needs to keep their tongue or lips in a certain position for a
        few seconds) and dynamic exercises (in which the child needs to move
        their articulators in a certain way).
      </Text>
      <BlogImage
        imgAlt="Make a Sound"
        imgTitle="Make a Sound"
        imgSrc={MakeASound}
      />
      <Text as="p">
        In Kokolingo, these exercises can be found on the first island, and are
        named Make a Sound. The examples are performed by Lucky the Pirate, and
        the child will be prompted to repeat the exercises after him. These
        exercises improve both strength and precision of the articulator muscles
        and build your child's muscle memory for basic movement patterns.
        Observe your child for possible signs of fatigue after several minutes
        of practice with these exercises; You may find it is better to practice
        in short bursts rather than long stretches.
      </Text>
      <Text as="h2">Syllables and Words</Text>
      <Text as="p">
        After practicing sound production in isolation (for example positioning
        our articulators for a sustained “sssss” consonant or a sustained “oh”
        vowel) the next step is to begin practicing simple one-syllable
        consonant vowel combinations as a building block towards producing
        longer words and phrases. Sometimes these syllables have meaning (“sss”
        and “oh” make the word “so” when put together) but many times the
        syllables don't have any intrinsic meaning. Syllables without a meaning
        on their own are still important to practice as they can be building
        blocks for longer multi-syllable words. Speech and language therapists
        put together lists of consonant vowel combinations depending on which
        speech sound is being targeted. At the beginning of this kind of
        practice, it is a good idea to target easier movement patterns first and
        introduce more difficult patterns as the child grows in ability and
        confidence with their skills.
      </Text>
      <Row className="justify-content-center">
        <Col xs={12} sm={10} md={6}>
          <BlogImage
            imgAlt="Funny Words"
            imgTitle="Funny Words"
            imgSrc={FunnyWords}
          />
        </Col>
        <Col xs={12} sm={10} md={6}>
          <BlogImage imgAlt="Words" imgTitle="Words" imgSrc={Words} />
        </Col>
      </Row>
      <Text as="p">
        In Kokolingo, the games practicing consonant vowel combinations are
        called Funny Words, because the “words” we ask you to repeat will sound
        a bit strange. You can practice them in two different activities, Climb
        Up and Hidden Pictures. In these activities your child needs to
        pronounce just one syllable (e.g., “pah” or “fee”) or one syllable pair
        (e.g., “pah – poh” or “fee – fai”). The most common method of practicing
        correct pronunciation is through use of word lists, so we created three
        activities for this purpose – Flash Cards, Find the Words, and Memory.
        These activities will specifically help your child practice words
        containing their target speech sound in initial position (beginning of a
        word), medial position (middle of a word), and final position (end of a
        word). Your child systematically acquires correct pronunciation of
        certain speech sounds, starting with the position in which it is easiest
        to get the correct pronunciation, and moving toward the positions in
        which it is more complex to attain correct pronunciation. These
        activities correct pronunciation and also enhance attention,
        concentration, and working memory.
      </Text>
      <Text as="h2">Sentences and Stories</Text>
      <Text as="p">
        By practicing speech sounds first in syllables and short words, correct
        pronunciation is learned in small, isolated linguistic units. When
        carrying these skills over into sentence-production, the child will be
        challenged to automatize correct pronunciation within longer units of
        speech. They continue to build skills starting with shorter sentences,
        and then, with practice, longer and more complex sentences. Kokolingo
        contains four activities for the automatization of correct pronunciation
        in longer connected speech: two activities for practicing sentences –
        Wheel of Fortune and Finish it Correctly, and two activities for
        practicing stories – Let's Read Together and Let's Read and Think It
        Over. These exercises follow a logical pathway from shorter, simpler
        sentences to longer, more complex ones. Of course, if the child is not
        able to repeat long sentences, we can always divide them into two or
        three shorter phrases. We do not want the child to feel frustrated but
        instead feel motivated to work toward a reasonable and attainable goal.
      </Text>
      <Row className="justify-content-center">
        <Col xs={12} sm={10} md={6}>
          <BlogImage
            imgAlt="Sentences"
            imgTitle="Senetences"
            imgSrc={Sentences}
          />
        </Col>
        <Col xs={12} sm={10} md={6}>
          <BlogImage imgAlt="Stories" imgTitle="Stories" imgSrc={Stories} />
        </Col>
      </Row>
      <Text as="p">
        When practicing correct pronunciation, we need to be systematic,
        consistent, and motivated to work toward our goals. For a child to feel
        motivated to complete speech activities, the exercises have to be fun in
        order to keep a young mind engaged; After all, kids learn through play.
        Kokolingo was designed to promote regular and systematic practice and
        offer progress monitoring, motivators, and fun activities. Because
        Kokolingo is a digital tool, it is ideal for children who are not
        motivated by printed materials or objects. It makes the speech
        therapist’s work and parents’ home exercises easier. Most importantly,
        it is fun for children.
      </Text>
      <Text as="p" bold>
        Author: Krešimir Perša, Speech and Language Therapist
      </Text>
      <BlogArticles />
    </PageNew>
  );
};

export default SpeechDisorderPage;
