import React from 'react';
import { Localize } from '@kokolingo/localisation';

import * as images from '../images';

const Testimonials = Object.keys(images)
  .filter((image) => images[image] && String(image).includes('testimonial'))
  .map((image, index) => {
    return {
      img: (
        <Localize.Img
          src={images[image]}
          titleID={`testimonial.ImageTitle${index + 1}`}
          altID={`testimonial.ImageAlt${index + 1}`}
          className="testimonial-image"
        />
      ),
      imgDesc: <Localize id={`testimonial.Description${index + 1}`} />,
      imgName: <Localize id={`testimonial.Name${index + 1}`} />,
      imgChildren: <Localize id={`testimonial.Children${index + 1}`} />,
    };
  });

export default { TESTIMONIALS: Testimonials };
