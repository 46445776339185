const getIsPromoDate = () => {
  const currentDate = new Date();
  const startDate = new Date('2024-11-25');
  const endDate = new Date('2024-12-01');

  return currentDate >= startDate && currentDate <= endDate;
};

export default {
  getIsPromoDate,
};
