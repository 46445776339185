const backToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

const anchorUrl = (hashLink) => {
  return hashLink.replace('/', '');
};

export default {
  backToTop,
  anchorUrl,
};
