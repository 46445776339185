import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { CardPost } from '../..';
import routes from '../../../routes';

import './index.scss';

import TherapistWithChild from '../../../images/educational-corner/blog28/Logoped-vjezba-izgovor-glasa-s-djetetom.jpg';
import ParentWithChild1 from '../../../images/educational-corner/blog33/roditelj-s-djetetom1.jpg';
import ParentWithChild4 from '../../../images/educational-corner/blog33/roditelj-s-djetetom4.jpg';

const Index = () => {
  return (
    <Row className="mb-3 mt-3 blog-latest-articles">
      <Col xs={12} sm={6} md={4}>
        <CardPost
          title="What are Speech Sound Disorders?"
          subtitle="A gift that most of us take for granted in life is the effortless"
          img={TherapistWithChild}
          url={routes.SPEECH_DISORDER}
        />
      </Col>
      <Col xs={12} sm={6} md={4}>
        <CardPost
          title="Online speech and language therapy"
          subtitle="A speech and language therapist or speech language pathologist is a licensed professional "
          img={ParentWithChild4}
          url={routes.ONLINE_SPEECH_THERAPY_EXERCISES}
        />
      </Col>
      <Col xs={12} sm={6} md={4}>
        <CardPost
          title="Remote speech and language therapy"
          subtitle="Remote therapy, also known as teletherapy, enables a speech and language therapy visit to occur through use"
          img={ParentWithChild1}
          url={routes.DISTANCE_SPEECH_THERAPY}
        />
      </Col>
    </Row>
  );
};

export default Index;
