import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import koko from '../../../images/new-landing/sounds-koko.svg';
import { Sounds } from '../../index';

import { Text } from '../../../components';

import './index.scss';

const SectionSound = () => {
  return (
    <Row className="section-sounds" noGutters>
      <Col md={{ offset: 1, span: 6 }} xs={{ offset: 1, span: 10 }}>
        <Text as="h2" bold>
          <Localize id="homePage.SectionSounds.Title" />
        </Text>
        <Sounds />
      </Col>
      <Col md={{ offset: 1, span: 3 }} className="d-none d-md-block">
        <img loading="lazy" src={koko} alt="" className="section-sounds-koko" />
      </Col>
    </Row>
  );
};

export default SectionSound;
