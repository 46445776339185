import React from 'react';

import { FAQItems } from '../../index';

import './index.css';

const FAQ = () => {
  return FAQItems.map((element, index) => (
    <div className="faq-page-block__container" key={index}>
      <p className="faq-page-block__container__question">{element.question}</p>
      <p className="faq-page-block__container__answer">{element.answer}</p>
    </div>
  ));
};

export default FAQ;
