import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { Row, Col } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import Text from '../Text';

import Navigation from './Navigation';

import './index.scss';

const Activities = ({ activities }) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const activity = activities[activeIndex - 1];
  return (
    <Row className="activity" key={activeIndex}>
      <Col md={7} className="activity-image">
        <LazyLoad classNamePrefix="activity-card-img" offset={100} once>
          <Localize.Img
            src={activity.img}
            titleID={`activityCard.ImageTitle${activeIndex}`}
            altID={`activityCard.ImageAlt${activeIndex}`}
            className="activity-card-img"
          />
        </LazyLoad>
      </Col>
      <Col md={5}>
        <Text as="h3" bold>
          <Localize id={`activityCard.Title${activeIndex}`} />
        </Text>
        <Text className="activity-description">
          <Localize
            id={`activityCard.Description${activeIndex}`}
            values={{
              ul: (list) => <ul>{list}</ul>,
              li: (listElement) => <li>{listElement}</li>,
            }}
          />
        </Text>
        <Navigation
          count={activities.length}
          index={activeIndex}
          onChange={setActiveIndex}
        />
      </Col>
    </Row>
  );
};

Activities.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string,
    })
  ),
};

Activities.defaultProps = {
  activities: [],
};

export default Activities;
