import * as React from 'react';
import { Localize, useIntlMessages } from '@kokolingo/localisation';
import { Title, BackToTopButton } from '../../../components';
import { Page, MediaItems } from '../../index';

import './index.css';

const Media = () => {
  const messages = useIntlMessages();

  return (
    <Page
      title={messages.meta.Media}
      description={messages.meta.MediaDescription}
    >
      <div className="media-page-block">
        <Title variant="dark">
          <Localize id="Media.Title" />
        </Title>
        {MediaItems.map((element, index) => (
          <div className="media-page-block__container" key={index}>
            <p className="media-page-block__container__media">
              <a
                className="media-page-block__container__media__link"
                href={element.link}
              >
                {element.text}
              </a>
            </p>
          </div>
        ))}
        <BackToTopButton />
      </div>
    </Page>
  );
};

export default Media;
