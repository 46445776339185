import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.css';

const Paragraph = ({ children, className }) => {
  const paragraphClassName = cn(
    'container-fluid',
    'paragraph-container',
    className
  );

  return <p className={paragraphClassName}>{children}</p>;
};

Paragraph.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Paragraph.defaultProps = {
  className: '',
};

export default Paragraph;
