/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

import { isDesktop } from 'react-device-detect';

function importImage(image) {
  let imageUrl;
  try {
    imageUrl = require(`../images/new-landing/activities/${
      process.env.GATSBY_LOCALE
    }${isDesktop ? '' : '/mobile'}/${image}`);
  } catch {
    try {
      imageUrl = require(`../images/new-landing/activities/default${
        isDesktop ? '' : '/mobile'
      }/${image}`);
    } catch {
      imageUrl = undefined;
    }
  }
  return imageUrl;
}

const makeASound = importImage('make_a_sound.png');
const funnyWords = importImage('funny_words.png');
const listenCarefully = importImage('listen_carefully.png');
const sentences = importImage('sentences.png');
const words = importImage('words.png');
const stories = importImage('stories.png');

const ACTIVITY_CARD = [
  { img: makeASound },
  { img: listenCarefully },
  { img: funnyWords },
  { img: words },
  { img: sentences },
  { img: stories },
];

export default ACTIVITY_CARD;
