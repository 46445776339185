import React from 'react';
import Slider from 'react-slick';
import LazyLoad from 'react-lazyload';
import { Localize, useIntlMessages } from '@kokolingo/localisation';

import testimonials from '../../../constants/testimonials';

import { Text, Card } from '../../../components';

import './index.scss';

const SectionCustomers = () => {
  const messages = useIntlMessages();

  const settings = {
    slidesToShow: 1,
    infinite: true,
    className: 'slick-slider-home',
    dots: true,
    arrows: true,
    swipe: true,
    swipeToSlide: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          centerMode: true,
          variableWidth: process.env.GATSBY_LOCALE !== 'hr',
        },
      },
    ],
  };

  return (
    <div className="section-customers">
      <Text as="h2" bold center>
        <Localize id="homePage.SectionCustomers.Title" />
      </Text>
      <Slider {...settings}>
        {testimonials.TESTIMONIALS.map(
          ({ img, imgDesc, imgName, imgChildren }, index) => (
            <Card size="large" key={index}>
              <Card.Badge>
                <LazyLoad offset={100} once>
                  {img}
                </LazyLoad>
              </Card.Badge>
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: messages.Review.stars,
                }}
              />
              <Text as="p">{imgDesc}</Text>
              <Text as="p" className="mb-0">
                {imgName}
              </Text>
              <Text as="p" size="small">
                {imgChildren}
              </Text>
            </Card>
          )
        )}
      </Slider>
    </div>
  );
};

export default SectionCustomers;
