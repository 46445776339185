import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { navItems } from '../..';

import kokolingoLogo from '../../../images/kokolingo_logo.svg';

import './index.css';

const NavigationBar = () => {
  function changeLogoLink(e) {
    if (window.location.pathname === '/') {
      e.preventDefault();
      window.scrollTo(0, 0);
      // eslint-disable-next-line no-restricted-globals
      history.pushState('', document.title, window.location.pathname);
    }
  }

  return (
    <Navbar
      id="navbar"
      collapseOnSelect
      expand="lg"
      sticky="top"
      fixed="top"
      variant="light"
    >
      <Navbar.Brand className="nav-link" href="/" onClick={changeLogoLink}>
        <img className="nav-logo" src={kokolingoLogo} alt="logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        className="justify-content-around"
        id="responsive-navbar-nav"
      >
        <Nav className="justify-content-center navbar-nav nav-ul">
          {navItems.map((element, index) => (
            <React.Fragment key={index}>{element}</React.Fragment>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
