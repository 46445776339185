import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

const ProgressiveImage = ({ src, placeholderSrc, alt }) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  return <img src={imgSrc} alt={alt} />;
};

ProgressiveImage.propTypes = {
  placeholderSrc: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

ProgressiveImage.defaultProps = {
  placeholderSrc: '',
  alt: '',
};

export default memo(ProgressiveImage);
