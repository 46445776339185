import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import * as images from '../../images';

import './index.scss';

const Icons = images.icons;

const Icon = ({ name, circle, variant, size }) => {
  const iconUrl = Icons[name];
  const className = cn(
    'icon',
    { icon__circle: circle },
    `icon__${variant}`,
    `icon__${size}`,
    `icon__${name}`
  );

  return (
    <span className={className}>
      <img src={iconUrl} alt="" />
    </span>
  );
};

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(Icons)).isRequired,
  circle: PropTypes.bool,
  variant: PropTypes.oneOf(['ghost', 'light', 'grey', 'secondary', 'academy']),
  size: PropTypes.oneOf(['regular', 'large', 'small']),
};

Icon.defaultProps = {
  circle: true,
  variant: undefined,
  size: 'regular',
};

export default Icon;
