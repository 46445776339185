const PREMIUM = [
  {
    available: true,
    descriptionId: 'pricing.PremiumAdditionalBenefit',
  },
  {
    available: true,
    descriptionId: 'pricing.DailyReport',
  },
  {
    available: true,
    descriptionId: 'pricing.Statistics',
  },
];

const SMART = [
  {
    available: true,
    descriptionId: 'pricing.SmartAdditionalBenefit',
  },
  {
    available: false,
    descriptionId: 'pricing.DailyReport',
  },
  {
    available: false,
    descriptionId: 'pricing.Statistics',
  },
];

const MINI = [
  {
    available: true,
    descriptionId: 'pricing.MiniAdditionalBenefit',
  },
  {
    available: false,
    descriptionId: 'pricing.DailyReport',
  },
  {
    available: false,
    descriptionId: 'pricing.Statistics',
  },
];

export default { PREMIUM, SMART, MINI };
