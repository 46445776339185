import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import {
  getCountry,
  getLanguageLandingUrl,
  parseLanguages,
} from '@kokolingo/localisation';

import Flags from './flags';

import 'flag-icons/css/flag-icons.min.css';
import './index.scss';

const LanguageDropdown = () => {
  const currentCountry = getCountry(process.env.GATSBY_LOCALE);

  if (!process.env.GATSBY_LANGUAGES) {
    return null;
  }

  const currentFlagCountry = Flags[currentCountry] || currentCountry;

  return (
    <DropdownButton
      bsPrefix="dropdown-button"
      className="languages-dropdown"
      alignRight
      title={
        <span className="nav-link nav-link-dropdown m-auto text-white text-center font-weight-bolder">
          <span className={`fi fi-${currentFlagCountry}`} />
        </span>
      }
    >
      {parseLanguages(process.env.GATSBY_LANGUAGES).map((language) => {
        const landingUrl = getLanguageLandingUrl(language);
        const country = getCountry(language);

        const flagCountry = Flags[country] || country;

        return (
          <Dropdown.Item
            className="text-dark"
            key={country}
            href={landingUrl}
            active={language === process.env.GATSBY_LOCALE}
            disabled={language === process.env.GATSBY_LOCALE}
          >
            <span className={`fi fi-${flagCountry}`} />
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
};

export default LanguageDropdown;
