import React from 'react';

import { Localize } from '@kokolingo/localisation';

import { Text } from '../../../components';

import { blogArticles } from '../..';

import './index.css';

const BlogArticles = () => {
  return (
    <div className="educational-corner__articles">
      <Text as="h4">
        <u>
          <Localize id="educationalCorner.OtherArticles" />
        </u>
      </Text>
      <Text as="p">
        <ul>
          {blogArticles.map((element, index) => (
            <li key={index}>{element}</li>
          ))}
        </ul>
      </Text>
    </div>
  );
};

export default BlogArticles;
