import React from 'react';
import PropTypes from 'prop-types';

const ActivityCard = ({ img, title, description }) => {
  return (
    <div className="col-lg-4 col-md-6 zz">
      <div className="activity-card">
        <h1 className="block2-card-title-text">{title}</h1>
        <p className="block2-card-desc">{description}</p>
        <div className="bottom">{img}</div>
      </div>
    </div>
  );
};

ActivityCard.propTypes = {
  img: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
};

export default ActivityCard;
