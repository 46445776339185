import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import './index.css';

const BlogContent = ({ children, className }) => {
  const blogContentClassName = cn('blog', className);
  return <div className={blogContentClassName}>{children}</div>;
};

BlogContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

BlogContent.defaultProps = {
  className: '',
};

export default BlogContent;
