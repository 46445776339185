import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import './index.css';

import { app } from '../../utils';

const Button = ({ children, variant, className, link, onClick }) => {
  const buttonClassName = variant
    ? cn('button', `button-${variant}`, className)
    : className;

  return (
    <button
      onClick={
        onClick ||
        (() => {
          window.location.href = app.linkApp([link]);
        })
      }
      className={buttonClassName}
      type="button"
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['mobile', 'header', 'end']),
  link: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  className: '',
  variant: null,
  link: '/login',
  onClick: null,
};

export default Button;
