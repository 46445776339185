import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import cn from 'classnames';

import './index.scss';

const Button = ({ children, variant, className, size, type, onClick, id }) => {
  const buttonClassName = cn(
    'button-new',
    `button-new__${variant}`,
    `button-new__${size}`,
    className
  );

  const Component = typeof onClick === 'string' ? Link : 'button';
  const props = { [typeof onClick === 'string' ? 'to' : 'onClick']: onClick };

  return (
    <Component id={id} className={buttonClassName} type={type} {...props}>
      {children}
    </Component>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['primary', 'academy', 'ghost']),
  size: PropTypes.oneOf(['large', 'small']),
  type: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

Button.defaultProps = {
  id: undefined,
  onClick: undefined,
  className: '',
  variant: 'primary',
  size: 'large',
  type: 'button',
};

export default Button;
