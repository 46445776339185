import * as React from 'react';

import { BlogImage, Text } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import kidsWithParents1 from '../../../images/educational-corner/blog33/roditelj-s-djetetom1.jpg';
import kidsWithParents2 from '../../../images/educational-corner/blog33/roditelj-s-djetetom2.jpg';
import kidsWithParents3 from '../../../images/educational-corner/blog33/roditelj-s-djetetom3.jpg';
import kidsWithParents4 from '../../../images/educational-corner/blog33/roditelj-s-djetetom4.jpg';

const HowToFindTimeForKids = () => {
  return (
    <PageNew
      title="KOKOLINGO - Kako pronaći vremena za vježbanje s djecom?"
      description="Logopedske vježbe ne odrađuju se samo jednom ili 
      dvaput tjedno u logopedskom kabinetu. Rad roditelja ili skrbnika 
      s djecom kod kuće i redovito vježbanje zadataka koji su im zadani 
      ključan su element uspjeha i napretka u ispravljanju izgovora glasova. 
      Međutim, kućni su nam rasporedi i bez vježbi često pretrpani obavezama 
      i mnogima se uklapanje još jedne aktivnosti čini kao nemoguća misija. 
      To je još naglašenije u vrijeme rada i škole od kuće."
      headline="Kako pronaći vremena za vježbanje s djecom?"
    >
      <Text as="p">
        Logopedske vježbe ne odrađuju se samo jednom ili dvaput tjedno u
        logopedskom kabinetu. Rad roditelja ili skrbnika s djecom kod kuće i
        redovito vježbanje zadataka koji su im zadani ključan su element uspjeha
        i napretka u ispravljanju izgovora glasova. Međutim, kućni su nam
        rasporedi i bez vježbi često pretrpani obavezama i mnogima se uklapanje
        još jedne aktivnosti čini kao nemoguća misija. To je još naglašenije u
        vrijeme rada i škole od kuće. Kada se sljedeći put razljutite pri
        pogledu na reklamu za neograničeni internetski promet gdje mama veselo
        tipka lijevom rukom, a drži malu bebu desnom, djeca s osmijehom
        rješavaju školske zadatke na svojim tabletima bez ikakve svađe, a tata
        im lijevom rukom pomaže, desnom rukom pokazuje tablicu na online
        sastanku i vježba stojeći na jednoj nozi, uzmite malu pauzu. Napravite
        lijepu veliku šalicu kave i stavite neke stvari na papir.
      </Text>
      <BlogImage
        imgAlt="Roditelj vjezba s djecom"
        imgTitle="Roditelj vjezba s djecom"
        imgSrc={kidsWithParents1}
      />
      <Text as="p">Uredna kuća</Text>
      <Text as="p">
        Neobično, ali svaki dobar savjet za planiranje poslovnih i kućanskih
        obaveza počinje čišćenjem. Ako malo razmislimo, neobično baš i nije, jer
        kada je oko nas sve uredno, lakše ćemo se koncentrirati i imat ćemo
        osjećaj bolje kontrole nad situacijom. Zato se svoj životni i radni
        prostor isplati dobro očistiti, ukloniti sve suvišno i učiniti ga takvim
        da bude što „prozračniji“ i jednostavniji za održavanje. Ne smijemo
        zaboraviti ni učiti djecu održavanju reda i pospremanju svojih stvari,
        kao i sudjelovanju u zajedničkim obavezama u održavanju reda u kući.
      </Text>
      <Text as="p">Planiranje i prioriteti</Text>
      <Text as="p">
        Često mislimo da ionako dobro znamo što treba napraviti i da nije
        potrebno posebno isplanirati jedan običan dan. Međutim, kada stvari
        stavimo na papir i počnemo razmišljati što je zaista važno, a što bismo
        samo htjeli napraviti, a možda i nije tako važno, plan će se sigurno
        značajno promijeniti. Kada prihvatimo neku obavezu, to često znači da za
        neku drugu nećemo imati vremena. Zato u glavi moramo jasno posložiti
        koje su naše vrijednosti i što nam je u životu važno. Prekrižimo ono što
        nije važno i probajmo na kraju svakog tjedna napraviti plan za sljedeći.
        Ili, ako tako više volite, početi novi tjedan njegovim planiranjem. Na
        čistom listu papira ili u digitalnom alatu, svejedno.
      </Text>
      <BlogImage
        imgAlt="Roditeljevo planiranje vremena"
        imgTitle="Roditeljevo planiranje vremena"
        imgSrc={kidsWithParents2}
      />
      <Text as="p">Popisi i kalendari</Text>
      <Text as="p">
        Popisi obaveza jako su korisni, ali nije dovoljno nešto samo pretvoriti
        u natuknicu na spisku. Svaku stvar treba uklopiti u tjedni (mjesečni)
        kalendar. Budite realistični kada brojite koliko je sati u danu ili dana
        u tjednu. Pročitajte svoje natuknice za sutra. Budite pošteni i ponovno
        razmislite koliko za svaku obavezu zaista treba vremena. Sada, kada ste
        ih zbrojili, je li ispalo da ovaj ponedjeljak zapravo ima 35 sati? Ili
        da ima baš 24 sata, ali ste zaboravili ubaciti spavanje?
      </Text>
      <Text as="p">Rutina</Text>
      <Text as="p">
        Svi vole pričati kako je rutina dosadna i kako je najgora stvar koja vam
        se može dogoditi da upadnete u kolotečinu. Međutim, kada imamo uhodanu
        rutinu, sve se obavlja brže. Kada već znate raspored važnijih stvari
        koje treba obaviti, nećete imati osjećaj da izmiču kontroli. Zato i
        djecu treba naučiti da za neke stvari postoji određeni raspored i
        određeno vrijeme. Vrijeme za spavanje, vrijeme za školu, vrijeme za
        igranje.
      </Text>
      <Text as="p">Delegiranje obaveza</Text>
      <Text as="p">
        Sigurno ima puno poslova koje umjesto vas mogu obaviti drugi. Svakome je
        od nas najlakše zamisliti kako ćemo sami nešto odraditi da ispadne onako
        kako želimo, ali ponekad je obaveza jednostavno previše. Važno je
        naučiti delegirati poslove, podijeliti teret i odgovornosti. To se
        odnosi i na djecu. Neka unaprijed znaju što tko treba obaviti. U početku
        će možda često zapitkivati i radit ćete zajedno, ali kasnije će
        nastaviti sami. Dopustite im da vam se dokažu svojom odgovornošću i
        sposobnošću.
      </Text>
      <BlogImage
        imgAlt="Roditelj vjezba s djecom"
        imgTitle="Roditelj vjezba s djecom"
        imgSrc={kidsWithParents3}
      />
      <Text as="p">Vrijeme za sebe</Text>
      <Text as="p">
        Ovu natuknicu često preskočimo, a kažu nam da je jako važna. Važno je
        odvojiti koji trenutak za sebe, sabrati misli, uzeti malu pauzu. Ne
        treba to biti puno. Jutarnje osobe možda će se probuditi pola sata prije
        drugih i sjesti uz šalicu kave. Netko drugi možda će provesti malu
        popodnevnu pauzu u dvorištu ili otići u kratku večernju šetnju. Napuniti
        kadu pjenom ili umočiti noge u lavor u skučenoj kupaonici i zadovoljno
        meditirati.
      </Text>
      <BlogImage
        imgAlt="Roditelj vjezba s djecom"
        imgTitle="Roditelj vjezba s djecom"
        imgSrc={kidsWithParents4}
      />
      <Text as="p">
        Na kraju donosimo i popis malih zamki u koje često upadamo, a za koje
        nismo ni svjesni koliko nam vremena oduzmu:
      </Text>
      <Text as="p">
        <ul>
          <li>
            Multitasking se često može pretvoriti u započinjanje i nezavršavanje
            više stvari odjednom. Dobro procijenite koliko ruku imate i na
            koliko se stvari odjednom realno može podijeliti pažnja.
          </li>
          <li>
            Kada radite za računalom ili mobitelom, izbjegavajte samo „baciti
            oko“, kliknuti na brzinu, skočiti na sekundu do nekog zanimljivog
            oglasa, stranice, gif-a. Ta će vam navika poremetiti koncentraciju i
            oduljiti posao
          </li>
          <li>
            Često trošimo vrijeme na stvari koje nam ne trebaju, ne vesele nas i
            nisu nam važne, poput pregledavanja web trgovina kada ništa ne
            trebamo kupiti, volontiranja za koje nismo imali vremena, dugih
            tračeva u kojima ne želimo sudjelovati, kompliciranih recepata na
            koje će djeca opet reći „bljak“. Možda je vrijeme da se neke stvari
            prekriže.
          </li>
          <li>
            Na kraju dana, kada nam je dosta svega, volimo se opuštati uz ekran
            pa to često potraje i do sitnih sati. Možda se i uspijemo opustiti,
            ali krajnji je rezultat da ćemo sutra jednostavno biti preumorni.
          </li>
          <li>
            U jednome danu može biti puno praznoga hoda, bilo da vam je
            nametnut, bilo da ste ga sami stvorili ne mogavši se odlučiti što
            ćete prije obaviti. Iskoristite onaj koji ne možete izbjeći (poput
            čekanja u čekaonici) na sitne obaveze poput pregledavanja e-mailova,
            a onaj koji ste sami stvorili ubuduće izbjegnite dobrim planiranjem.
          </li>
        </ul>
      </Text>
      <SectionBlog />
    </PageNew>
  );
};

export default HowToFindTimeForKids;
