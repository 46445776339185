import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { VideoThumbnail } from '../../../images';

import { Text, Icon, ButtonNew } from '../../../components';
import appUrls from '../../../constants/appUrls';
import emoji from '../../../constants/emoji';

import './index.scss';

const VideoAbout = ({ videoSrc }) => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoRef = useRef();
  const className = cn('video-about', { 'video-about__playing': videoPlaying });

  useEffect(() => {
    videoRef.current.addEventListener('play', () => {
      setVideoPlaying(true);
    });
    videoRef.current.addEventListener('pause', () => {
      setVideoPlaying(false);
    });
  }, []);

  return (
    <Row className={className} noGutters>
      <Col className="col-auto">
        <div
          className="video-about-container"
          onClick={() =>
            videoPlaying ? videoRef.current.pause() : videoRef.current.play()
          }
        >
          <video ref={videoRef} src={videoSrc} poster={VideoThumbnail} />
          <Icon name="play" />
        </div>
        <Text as="h3" color="primary" center bold>
          <Localize id="homePage.SectionVideoAbout.Caption" />
        </Text>
        <Text as="p" color="primary" center bold>
          <Localize id="homePage.SectionVideoAbout.Description" />
        </Text>
        <ButtonNew onClick={appUrls.HOME}>
          <Localize id="homePage.SectionVideoAbout.CTA" />{' '}
          {emoji.POINTING_FINGER}
        </ButtonNew>
      </Col>
    </Row>
  );
};

VideoAbout.propTypes = {
  videoSrc: PropTypes.string.isRequired,
};

export default VideoAbout;
