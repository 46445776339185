import React from 'react';
import Slider from 'react-slick';
import './index.scss';
import { AshaCard } from './components';

const AshaSlider = () => {
  const settings = {
    dots: true,
    className: 'center',
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
    speed: 500,
    arrows: false,
  };

  const images = [
    {
      img:
        'https://media.istockphoto.com/id/944585218/photo/four-kids-hanging-out-together-in-the-garden.jpg?s=612x612&w=0&k=20&c=XnT6PGRD3SQ-aViAMW7HiJzkP6ShXjnNnOByJk6o2QA=',
    },
    {
      img:
        'https://media.istockphoto.com/id/1330890956/photo/funny-smiling-black-child-school-girl-with-glasses-hold-books-on-her-head.jpg?b=1&s=170667a&w=0&k=20&c=ITF0YozLBsROafybw06gpGQoLBx0KOzhz2s0LTgOP0c=',
    },
    {
      img:
        'https://www.sos-childrensvillages.org/getmedia/3db518ed-a765-4687-b717-1cd0c2740565/ZagrebCroatiatpa_picture_29170600.jpg?width=600&height=300&ext=.jpg',
    },
    {
      img: 'https://aplus.ua/wp-content/uploads/2021/04/lvovskaya_1.jpg',
    },
    {
      img: 'https://happykids-park.com.ua/uploads/photos/2079221204.jpg',
    },
    {
      img: 'https://aplus.ua/wp-content/uploads/2021/04/bvz047091.jpg',
    },
    {
      img:
        'https://digitallearning.eletsonline.com/wp-content/uploads/2019/12/Why-School-education-crucial-for-child-development.jpg',
    },
    {
      img:
        'https://www.childrens.com/wps/wcm/connect/childrenspublic/eafbdbb9-e267-4ded-923e-b35add3cfa63/shutterstock_142808314_800x480.jpg?MOD=AJPERES&CVID=',
    },
  ];

  return (
    <div className="asha-section-customers">
      <Slider {...settings}>
        {images.map((item, index) => (
          <AshaCard size="large" key={index}>
            <img
              className="slider-img"
              style={{ width: '100%', borderRadius: '2.6vw' }}
              src={item.img}
              alt="slider card"
            />
          </AshaCard>
        ))}
      </Slider>
    </div>
  );
};

export default AshaSlider;
