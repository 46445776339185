/* eslint-disable camelcase */
import messages_en_uk from '../constants/intl/en-uk.json';
import messages_en_us from '../constants/intl/en-us.json';
import messages_hr from '../constants/intl/hr.json';
import messages_pl from '../constants/intl/pl.json';

const getMessages = () => {
  const messages = {
    hr: messages_hr,
    'en-us': messages_en_us,
    'en-uk': messages_en_uk,
    pl: messages_pl,
  };

  return messages[process.env.GATSBY_LOCALE];
};

export default { getMessages };
