import React from 'react';

import YouTubeVideo from '../YouTubeVideo';

import './index.scss';

const AshaVideo = () => {
  return (
    <div className="asha-video">
      <YouTubeVideo videoId="8ZTYRGvA7ik" />
    </div>
  );
};

export default AshaVideo;
