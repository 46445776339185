import React from 'react';

import contactItems from '../../../constants/contacts/contactItems';

import './index.css';

const Contacts = () => {
  return contactItems.CONTACTS.map((contact, index) => (
    <div className="col" key={index}>
      {contact}
    </div>
  ));
};

export default Contacts;
