import React, { useState } from 'react';
import axios from 'axios';
import { Form, Spinner } from 'react-bootstrap';
import { Localize, useIntlMessages } from '@kokolingo/localisation';

import appUrls from '../../../constants/appUrls';
import routes from '../../../routes';

import { ButtonNew, Input, Text, Tooltip } from '../../../components';

import emoji from '../../../constants/emoji';

import './index.scss';

const STEPS = {
  EMAIL: 'email',
  PASSWORD: 'password',
};

const initialFormValues = {
  email: '',
  password: '',
};

const initialErrorsValues = {
  email: false,
  password: false,
};

const RegisterForm = () => {
  const [activeStep, setActiveStep] = useState(STEPS.EMAIL);
  const [submitting, setSubmitting] = useState();
  const [formState, setFormState] = useState(initialFormValues);
  const [errors, setErrors] = useState(initialErrorsValues);

  const messages = useIntlMessages();

  const handleInputChange = (event) => {
    const { target } = event;
    const { value } = target;
    const { type } = target;

    setFormState({
      ...formState,
      [type]: value,
    });
  };

  const validateEmail = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setErrors({
      ...errors,
      email: !regex.test(email),
    });

    return regex.test(email);
  };

  const validatePassword = (password) => {
    const regex = /^([a-zA-Z0-9_-]){8,16}$/;

    setErrors({
      ...errors,
      password: !regex.test(password),
    });

    return regex.test(password);
  };

  const resetForm = () => {
    setFormState(initialFormValues);
    document.getElementById('email').value = '';
    document.getElementById('password').value = '';
    setActiveStep(STEPS.EMAIL);
  };

  const resetPassword = () => {
    document.getElementById('password').value = '';
    setFormState({
      ...formState,
      password: '',
    });
    setSubmitting(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    const { email, password } = formState;
    const passwordCorrect = validatePassword(password);
    const fullName = email.substring(0, email.lastIndexOf('@'));
    const studentFullName = `${fullName}'s child`;

    return passwordCorrect
      ? axios
          .post(appUrls.API_REGISTER, {
            studentFullName,
            fullName,
            email,
            password,
            phoneNumber: '0',
            city: '',
            gdpr: true,
          })
          .then((response) => {
            if (response.status === 201) {
              window.location.href = `${appUrls.APP_LOGIN}?token=${response.data.token}`;
            }
          })
          .catch((error) => {
            if (error.response?.data?.message === 'error.feedback.emailInUse') {
              alert(messages.homePage.RegisterError.Email);
            } else if (!error.response) {
              alert(messages.homePage.RegisterError.Server);
            } else {
              alert(messages.homePage.RegisterError.Unknown);
            }
            setSubmitting(false);
            resetForm();
          })
      : resetPassword();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div hidden={activeStep !== STEPS.EMAIL}>
        <Tooltip
          placement="top-end"
          show={errors.email}
          tooltipMessage={messages.homePage.RegisterFormError.Email}
        >
          <Form.Group className="mb-3 input-group">
            <Input
              id="email"
              type="email"
              className="form-control"
              placeholder={messages.input.email}
              onChange={handleInputChange}
              error={errors.email}
            />
            <Input.Append>
              <ButtonNew
                size="small"
                onClick={() => {
                  return validateEmail(formState.email)
                    ? setActiveStep(STEPS.PASSWORD)
                    : null;
                }}
              >
                <Localize id="homePage.SectionFooter.Next" />
              </ButtonNew>
            </Input.Append>
          </Form.Group>
        </Tooltip>
      </div>
      <div hidden={activeStep !== STEPS.PASSWORD}>
        <Tooltip
          placement="top-end"
          show={errors.password}
          tooltipMessage={messages.homePage.RegisterFormError.Password}
        >
          <Form.Group className="mb-3 input-group">
            <Input
              id="password"
              type="password"
              className="form-control"
              placeholder={messages.input.password}
              onChange={handleInputChange}
              error={errors.password}
            />
            <Input.Append>
              {submitting ? (
                <Spinner
                  as="span"
                  className="mr-1"
                  animation="border"
                  role="status"
                />
              ) : (
                <ButtonNew type="submit" size="small">
                  <Localize id="homePage.SectionFooter.CTA" />{' '}
                  {emoji.POINTING_FINGER}
                </ButtonNew>
              )}
            </Input.Append>
          </Form.Group>
        </Tooltip>
      </div>
      <Text as="p" size="small" light color="light">
        <Localize
          id="homePage.SectionFooter.termsOfService"
          values={{
            termsOfService: (linkText) => (
              <a href={routes.TOS} target="_blank" rel="noreferrer">
                {linkText}
              </a>
            ),
            privacyPolicy: (linkText) => (
              <a href={routes.PRIVACY_POLICY} target="_blank" rel="noreferrer">
                {linkText}
              </a>
            ),
          }}
        />
      </Text>
    </Form>
  );
};

export default RegisterForm;
