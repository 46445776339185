import React from 'react';

import { IntlProvider } from '@kokolingo/localisation';

import { translations } from './src/utils';
import { ModalProvider } from './src/components';

import './src/style/activities.css';
import './src/style/block2.css';
import './src/style/block3.css';
import './src/style/block4_2.css';
import './src/style/block5.css';
import './src/style/discount-banner.css';
import './src/style/modal_v3.css';
import './src/style/pricing.css';
import './src/style/style_v3.css';
import './src/style/style2.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './src/style/style.css';
import './src/style/colors.css';
import './src/style/fonts.css';

export const wrapRootElement = ({ element }) => {
  return (
    <IntlProvider
      messages={translations.getMessages()}
      locale={process.env.GATSBY_LOCALE}
      defaultLocale="hr"
    >
      <ModalProvider>{element}</ModalProvider>
    </IntlProvider>
  );
};
