import React from 'react';

import Text from '../../components/Text';
import Icon from '../../components/Icon';

export default () => {
  return (
    <>
      <Text as="h4" color="light" bold>
        Social Media
      </Text>
      <div className="footer-new-socials">
        <a
          href="https://www.facebook.com/KokolingoApp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="fb" variant="light" size="small" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/KokolingoApp"
        >
          <Icon name="tw" variant="light" size="small" />
        </a>
        <a
          href="https://www.instagram.com/kokolingo_app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="ig" variant="light" size="small" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCRKKFvHuOTV-i80d1zZj67A"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="yt" variant="light" size="small" />
        </a>
      </div>
    </>
  );
};
