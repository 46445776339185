import * as React from 'react';
import { Localize, useIntlMessages } from '@kokolingo/localisation';
import { Title, BackToTopButton } from '../../../components';
import { Page, PartnersItems } from '../../index';

import './index.css';

const Partners = () => {
  const messages = useIntlMessages();

  return (
    <Page
      title={messages.meta.Partners}
      description={messages.meta.PartnersDescription}
    >
      <div className="partners-page-block">
        <Title variant="dark">
          <Localize id="Partners.Title" />
        </Title>
        {PartnersItems.map((element, index) => (
          <div className="partners-page-block__container" key={index}>
            <p className="partners-page-block__container__partner">
              <a
                className="partners-page-block__container__partner__link"
                href={element.link}
              >
                {element.text}
              </a>
            </p>
          </div>
        ))}
        <BackToTopButton />
      </div>
    </Page>
  );
};

export default Partners;
