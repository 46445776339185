import React, { useCallback, useEffect, useState } from 'react';
import { Localize, useIntlMessages } from '@kokolingo/localisation';
import { ButtonGroup, Row } from 'react-bootstrap';

import { html, useScript } from '../../../utils';
import routes from '../../../routes';
import { PricingItems, Text, ButtonNew } from '../../../components';
import activityTypesItems from '../../../constants/activityTypesItems';
import PricingPackage from '../PricingPackage';
import additionalBenefitsItems from '../../../constants/additionalBenefitsItems';
import BASIC_PAYMENT_METHODS from '../../../constants/paymentMethods';

import './index.scss';

const DefaultSectionPricing = require('../../default/SectionPricing').default;

const FastSpringScript = {
  src:
    'https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.7/fastspring-builder.min.js',
  id: 'fsc-api',
  type: 'text/javascript',
  'data-popup-closed': 'onFastSpringPopupClosed',
  'data-popup-webhook-received': 'onFastSpringPopupWebhookReceived',
  'data-error-callback': 'onFastSpringError',
  'data-storefront': process.env.GATSBY_FASTSPRING_STORE,
};

const kokolingoPro = [
  {
    numberOfUsers: 1,
    price: 119.0,
  },
  {
    numberOfUsers: 10,
    oldPrice: 1190.0,
    price: 1071.0,
    discount: 10,
  },
  {
    numberOfUsers: 25,
    oldPrice: 2975.0,
    price: 2499.0,
    discount: 16,
  },
  {
    numberOfUsers: 50,
    oldPrice: 5950.0,
    price: 4462.5,
    discount: 25,
  },
  {
    numberOfUsers: 100,
    oldPrice: 11900.0,
    price: 7973.0,
    discount: 33,
  },
];

const PARENTS = 'pricing';
const FACILITIES = 'pricing-pro';

const SectionPricing = () => {
  const messages = useIntlMessages();
  const { scriptLoaded } = useScript(FastSpringScript);
  const [activeKey, setActiveKey] = useState();

  function hashChange() {
    if (window.location.hash === html.anchorUrl(routes.PARENTS_ANCHOR)) {
      setActiveKey(PARENTS);
    } else if (
      window.location.hash === html.anchorUrl(routes.FACILITIES_ANCHOR)
    ) {
      setActiveKey(FACILITIES);
    }
  }

  useEffect(() => {
    if (activeKey) {
      window.history.pushState(null, null, `#${activeKey}`);
    }
    hashChange();

    window.addEventListener('hashchange', hashChange);

    return () => {
      window.removeEventListener('hashchange', hashChange);
    };
  }, [activeKey]);

  useEffect(() => {
    const onSuccess = () => {
      window.fastspring.builder.reset();
      window.alert(messages.payment.Success);
    };

    const onError = () => {
      window.alert(messages.payment.Error);
    };

    window.onFastSpringError = onError;

    window.onFastSpringPopupClosed = (orderReference) => {
      if (orderReference) {
        onSuccess();
      } else {
        onError();
      }
    };

    window.onFastSpringPopupWebhookReceived = (order) => {
      const { fulfillments } = order?.items[0];
      const fulfillmentKey = Object.keys(fulfillments).filter((fulfillment) =>
        fulfillment?.startsWith('kokolingo')
      )?.[0];

      window.licenseKey = fulfillments?.[fulfillmentKey]?.[0]?.license;

      if (!BASIC_PAYMENT_METHODS.includes(order?.payment?.type)) {
        if (window.licenseKey) {
          onSuccess();
        } else {
          onError();
        }
      }
    };
  }, [messages.payment.Error, messages.payment.Success]);

  const onSelect = useCallback(
    (packageName) => {
      if (window.fastspring && scriptLoaded) {
        window.fastspring.builder.add(packageName);
        window.fastspring.builder.checkout();
      }
    },
    [scriptLoaded]
  );

  return (
    <Row noGutters className="section-pricing-main">
      <ButtonGroup>
        <ButtonNew
          className={activeKey === FACILITIES ? 'button-new__light' : ''}
          onClick={() => setActiveKey(PARENTS)}
        >
          <Localize id="pricing.ParentsTitle" />
        </ButtonNew>
        <ButtonNew
          className={activeKey !== FACILITIES ? 'button-new__light' : ''}
          onClick={() => setActiveKey(FACILITIES)}
        >
          <Localize id="pricing.Facilities" />
        </ButtonNew>
      </ButtonGroup>
      <div id="pricing-pro" />
      {activeKey !== FACILITIES ? (
        <div>
          <DefaultSectionPricing>
            {(props) => (
              <PricingPackage onPackageSelect={onSelect} {...props} />
            )}
          </DefaultSectionPricing>
        </div>
      ) : (
        <div>
          <Row className="section-pricing" noGutters>
            <div className="pricing-title-description">
              <Text as="h3" color="light" bold>
                <Localize id="pricing.FacilitiesTitle" />
              </Text>
              <Text as="p" center color="light">
                <Localize id="pricing.FacilitiesDescription" />
              </Text>
            </div>
            <div className="fade show">
              <div className="pro-packages-container">
                <div className="container-fluid">
                  <Row className="nowrap">
                    <div className="col">
                      {kokolingoPro.map((item, index) => (
                        <div
                          key={index}
                          className="pricing-container pricing-card row"
                        >
                          <div className="w-75">
                            <Text as="h4" bold>
                              <Localize
                                id="pricing.ProLicenseLabel"
                                values={{
                                  numberOfUsers: item.numberOfUsers.toString(),
                                  plural: item.numberOfUsers > 1 ? 's' : '',
                                }}
                              />
                            </Text>
                            <a
                              href="https://kokolingo.onfastspring.com/slps-store"
                              target="_blank"
                              className="pricing-button small"
                              rel="noreferrer"
                            >
                              <Localize id="pricing.BuyPackage" />
                            </a>
                          </div>
                          <div className="pro-license w-25">
                            {item.discount && (
                              <span className="pro-license-discount">
                                {item.discount}% Off
                              </span>
                            )}
                            <br />
                            {item.oldPrice && (
                              <>
                                <span className="pro-license-old-price">
                                  ${item.oldPrice.toFixed(2)}
                                </span>
                                <br />
                              </>
                            )}
                            <span className="pro-license-price">
                              ${item.price.toFixed(2)}
                            </span>
                          </div>
                        </div>
                      ))}
                      <div className="pricing-title-description pricing-explanation">
                        <b>
                          <span style={{ color: '#cc200a' }}>**</span>
                        </b>{' '}
                        <Localize id="pricing.ProLicenseDescription" />
                      </div>
                    </div>
                    <div className="pricing-container">
                      <div className="pricing-benefits pricing-text">
                        <Text as="h3" bold>
                          <Localize id="pricing.ProLicenseFeatures" />
                        </Text>
                        <p>
                          <b>
                            <Localize id="pricing.TypesOfActivities" />
                          </b>
                        </p>
                        <PricingItems packages={activityTypesItems.PREMIUM} />
                        <p>
                          <b>
                            <Localize id="pricing.AdditionalBenefits" />
                          </b>
                        </p>
                        <PricingItems
                          packages={additionalBenefitsItems.PREMIUM}
                        />
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
              <div className="pro-packages-container-mobile">
                {kokolingoPro.map((item, index) => (
                  <div
                    key={index}
                    className="pricing-container pricing-card row"
                  >
                    <div className="w-75">
                      <Text as="h4" bold>
                        <Localize
                          id="pricing.ProLicenseLabel"
                          values={{
                            numberOfUsers: item.numberOfUsers.toString(),
                            plural: item.numberOfUsers > 1 ? 's' : '',
                          }}
                        />
                      </Text>
                      <a
                        href="https://kokolingo.onfastspring.com/slps-store"
                        target="_blank"
                        className="pricing-button small"
                        rel="noreferrer"
                      >
                        <Localize id="pricing.BuyPackage" />
                      </a>
                    </div>
                    <div className="pro-license w-25">
                      {item.discount && (
                        <span className="pro-license-discount">
                          {item.discount}% Off
                        </span>
                      )}
                      <br />
                      {item.oldPrice && (
                        <>
                          <span className="pro-license-old-price">
                            ${item.oldPrice.toFixed(2)}
                          </span>
                          <br />
                        </>
                      )}
                      <span className="pro-license-price">
                        ${item.price.toFixed(2)}
                      </span>
                    </div>
                  </div>
                ))}
                <div className="pricing-container">
                  <div className="pricing-benefits pricing-text">
                    <Text as="h3" bold>
                      <Localize id="pricing.ProLicenseFeatures" />
                    </Text>
                    <p>
                      <b>
                        <Localize id="pricing.TypesOfActivities" />
                      </b>
                    </p>
                    <PricingItems packages={activityTypesItems.PREMIUM} />
                    <p>
                      <b>
                        <Localize id="pricing.AdditionalBenefits" />
                      </b>
                    </p>
                    <PricingItems packages={additionalBenefitsItems.PREMIUM} />
                  </div>
                </div>
                <div className="pricing-title-description pricing-explanation">
                  <b>
                    <span style={{ color: '#cc200a' }}>**</span>
                  </b>{' '}
                  <Localize id="pricing.ProLicenseDescription" />
                </div>
              </div>
            </div>
          </Row>
        </div>
      )}
    </Row>
  );
};

export default SectionPricing;
