import React, { useEffect } from 'react';

import backToTopButton from '../../images/right.png';
import { html } from '../../utils';
import './index.css';

const BackToTopButton = () => {
  function scrollFunction() {
    const BACK_TO_TOP_BUTTON = document.getElementById('back-to-top-button');

    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      if (BACK_TO_TOP_BUTTON.classList.contains('fade-in') === false) {
        BACK_TO_TOP_BUTTON.style.display = 'block';
        BACK_TO_TOP_BUTTON.classList.toggle('fade-in');
      }
    } else {
      BACK_TO_TOP_BUTTON.style.display = 'none';
      BACK_TO_TOP_BUTTON.classList.remove('fade-in');
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollFunction);

    return () => {
      window.removeEventListener('scroll', scrollFunction);
    };
  }, []);

  return (
    <img
      alt="Gumb koji vodi na vrh stranice"
      title="Gumb za vrh stranice"
      id="back-to-top-button"
      className="button-back-to-top"
      src={backToTopButton}
      onClick={html.backToTop}
    />
  );
};

export default BackToTopButton;
