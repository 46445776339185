import React from 'react';
import { LogoCompany } from '../../../components';
import attrs from '../../../constants/contacts/attributes';

const FooterHeader = () => {
  return (
    <div className="row text-center">
      <div className="col-12">
        <a href={attrs.company} rel="noopener noreferrer" target="_blank">
          <LogoCompany />
        </a>
      </div>
    </div>
  );
};

export default FooterHeader;
